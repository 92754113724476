import React, { useState } from "react";
import { MoreVert } from "@mui/icons-material";

import { Button, IconButton, Popover, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MoodButton from "../../work_suggest/MoodButton";
import { Link } from "react-router-dom";

const Root = styled("div")`
  display: flex;
  align-items: center;
`;

const Vert = styled(MoreVert)(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontSize: "18px",
}));

const TextLink = styled(Typography)(({ theme }: any) => ({
  cursor: "pointer",
  display: "inline-block",
  position: "relative",
  textDecoration: "none",
  fontSize: "13px",
  "&:after": {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "1px",
    bottom: "0",
    left: "0",
    backgroundColor: theme.palette.text.secondary,
    transform: "scaleX(0)",
    transition: "transform 0.25s ease-out",
    transformOrigin: "bottom right",
  },
  "&:hover:after": {
    transform: "scaleX(1)",
    transformOrigin: "bottom left",
    background: theme.palette.primary.light,
  },
}));

const WorkMoodFilter: React.FC<any> = ({
  handleMood,
  httpParams,
  testData,
  isBenef,
}) => {
  console.log("testData", testData);
  const [value, setValue] = useState<any>(httpParams?.mood || [0, 1, 2, 3]);
  const mobileMenuId = "primary-search-account-menu-mobile";

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (val: number | boolean) => {
    setValue(val);
    handleMood(val);
  };

  return (
    <Root>
      {/* <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "mood switch" }}
          />
        }
        label="Mood"
      /> */}
      {testData && !isBenef && !testData?.isPassed ? (
        <></>
      ) : (
        // <MoodSwitch
        //   disabled={!testData?.isPassed}
        //   checked={checked}
        //   handleChange={handleChange}
        // />
        <>
          {testData?.isPassed ? (
            <MoodButton
              mood={value}
              handleChange={handleChange}
              preppend={
                testData?.isPassed && isBenef ? (
                  <>
                    <IconButton
                      sx={{ marginLeft: "-15px" }}
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <Vert />
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={() => navigate("/app/mood")}
                      >
                        Voir mes résultats
                      </Button>
                    </Popover>
                  </>
                ) : (
                  <></>
                )
              }
            />
          ) : (
            <>
              {isBenef && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <Link to="/app/mood">
                    <TextLink>{"Passer mood"}</TextLink>
                  </Link>
                  <img
                    style={{ width: "30px", marginLeft: "5px" }}
                    src="/static/mood/logo.svg"
                    alt="mood icon"
                  />
                </div>
              )}
            </>
          )}
        </>
      )}

      {/* {testData?.isPassed && isBenef ? (
        <>
          <IconButton
            sx={{ marginLeft: "-15px" }}
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Vert />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Button variant="text" onClick={() => navigate("/app/mood")}>
              Voir mes résultats
            </Button>
          </Popover>
        </>
      ) : (
        <></>
      )} */}
    </Root>
  );
};

export default WorkMoodFilter;
