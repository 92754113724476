export const getNumericDate = (date: Date) =>
  date
    .toLocaleDateString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })
    .toLocaleLowerCase()
    .split("/")
    .map((x) => (x.length === 1 ? `0${x}` : x))
    .join("/");
