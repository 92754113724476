import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import StoreIcon from "@mui/icons-material/Store";
import NotificationsIcon from "@mui/icons-material/Notifications";
import WorkIcon from "@mui/icons-material/Work";
import HomeIcon from "@mui/icons-material/Home";
import WidgetsIcon from "@mui/icons-material/Widgets";
import PeopleIcon from "@mui/icons-material/People";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const AdminTabAnchor = ({ current, setCurrent }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const advisorOrRecruiter = context === "advisor" || context === "recruiter";
  const list = [
    ...(advisorOrRecruiter ? ["home", "common"] : []),
    ...(context === "admin" ? ["general", "display", "notif", "works"] : []),
    ...(advisorOrRecruiter ? [context] : []),
    ...(advisorOrRecruiter ? [`people_${context}`] : []),
    ...(context === "admin" ? ["widget"] : []),
  ];
  const icons = [
    ...(advisorOrRecruiter ? [<HomeIcon sx={{ fontSize: "16px" }} />] : []),

    ...(advisorOrRecruiter ? [<StoreIcon sx={{ fontSize: "16px" }} />] : []),

    ...(context === "admin"
      ? [
          <NotificationsIcon sx={{ fontSize: "16px" }} />,
          <StoreIcon sx={{ fontSize: "16px" }} />,
          <GridViewIcon sx={{ fontSize: "16px" }} />,
          <WorkIcon sx={{ fontSize: "16px" }} />,
        ]
      : []),
    ...(advisorOrRecruiter ? [<PeopleIcon sx={{ fontSize: "16px" }} />] : []),
    ...(advisorOrRecruiter ? [<PeopleIcon sx={{ fontSize: "16px" }} />] : []),
    <WidgetsIcon sx={{ fontSize: "16px" }} />,
  ];

  return (
    <Box
      sx={{
        zIndex: 10,
        position: "fixed",
        top: { xs: "35%", md: "10%" },
        bottom: "auto",
        width: "150px",
        left: "auto",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 2,
          borderRadius: "13px",
          borderTopLeftRadius: "0px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "13px",
        }}
      >
        <ul style={{ listStyle: "none" }}>
          {list?.map((el: string, i: number) => (
            <li
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: current === i ? "blue" : "black",
              }}
              onClick={() => {
                navigate(
                  `/app/params/${
                    i === 0
                      ? context === "admin"
                        ? "general"
                        : "home"
                      : i === 1
                      ? context === "admin"
                        ? "display"
                        : "general"
                      : i === 2
                      ? context === "admin"
                        ? "notif"
                        : "display"
                      : i === 3
                      ? "works"
                      : i === 4 && context
                  }`
                );
                setCurrent(i);
              }}
              key={i}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  minWidth: "26px",
                  minHeight: "26px",
                  borderRadius: "50%",
                  backgroundColor: "#F0F0F0",
                  mb: i === list?.length - 1 ? 0 : 2,
                }}
              >
                {icons[i]}
              </Box>
              <Typography
                color={current === i ? "primary" : "secondary"}
                fontWeight="bold"
                variant="body1"
                sx={{ mb: i === list?.length - 1 ? 0 : 2, ml: 1 }}
              >
                {t(`admin.tab.${el}`)}
              </Typography>
            </li>
          ))}
        </ul>
      </Paper>
    </Box>
  );
};

export default AdminTabAnchor;
