export const rolesKeys: any = {
  ROLE_COLLAB: "collab",
  ROLE_CANDIDATE: "candidate",
  ROLE_BENEF: "benef",
  ROLE_RH: "rh",
  ROLE_MANAGER: "manager",
  ROLE_ADMIN: "admin",
  ROLE_RECRUITER: "recruiter",
  ROLE_ADVISOR: "advisor",
  // ROLE_ADMINREFERENTIAL: "admin_referential",
};

export default rolesKeys;
