import { Container, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CardIllustration from "../components/common/CardIllustration";
import Page from "../components/Page";
import AccountParams from "../components/params/AccountParams";
import SharesBenef from "../components/params/SharesBenef";
import SharesCandidate from "../components/params/SharesCandidate";
import { useSelector } from "react-redux";
import SharesCollab from "../components/_collab/home/shares/SharesCollab";

const AccountParamsPage = () => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const disableEditShares = useSelector(
    (state: any) => state?.home?.paramjson?.disableEditShares
  );

  return (
    <Page title={t("profile_menu.params")}>
      <Container sx={{ pt: 13, pb: 5 }}>
        <CardIllustration type="params" />
        <Stack spacing={5}>
          {!disableEditShares && (
            <>
              {/* {context === "benef" && (
                <div>
                  <SharesBenef />
                </div>
              )}
              {context === "candidate" && (
                <div>
                  <SharesCandidate />
                </div>
              )} */}
              {context === "collab" && (
                <div>
                  <SharesCollab />
                </div>
              )}
            </>
          )}
          <div>
            <AccountParams />
          </div>
        </Stack>
      </Container>
    </Page>
  );
};

export default AccountParamsPage;
