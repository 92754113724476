import { Box, ButtonBase, styled, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FeedbackModal from "../components/feedback/FeedbackModal";
import AdvisorContextMenu from "../contextMenus/advisor/AdvisorContextMenu";
import { useDispatch } from "react-redux";
import CollabContextMenu from "../contextMenus/collaborators/CollabContextMenu";
import RecruiterContextMenu from "../contextMenus/recruiter/RecruiterContextMenu";
import AdminContextMenu from "../contextMenus/admin/AdminContextMenu";
import { shallowEqual, useSelector } from "react-redux";
import FeedbackPeModal from "../components/feedback/FeedbackPeModal";
import { setUserPartial } from "../redux/actions/userActions";
import RHContextMenu from "../contextMenus/RHContextMenu";

const isGom = process.env.REACT_APP_GOM === "true";

const ContactButton = styled(ButtonBase)(({ theme }: any) => ({
  background: theme.palette.secondary.light,
  marginBottom: "20px",
  color: "white",
  borderRadius: "50px",
  marginLeft: "7px",
  marginRight: "7px",
  display: isGom ? "inherit" : "none",
  padding: "13px 20px",
  width: "194px",
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "scale(1.01)",
  },
}));

const LogoContainer = styled(Box)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px",
  height: "75px",
  padding: "10px",
  borderRadius: theme.shape.borderRadiusSm,
}));

const Logo = styled("img")({ maxHeight: "75px" });

const CguLink = styled(Link)(({ theme }: any) => ({
  cursor: "pointer",
  textDecoration: "none",
  color: theme.palette.tests.blue.main,
}));

const MailtoLink = styled("a")(({ theme }: any) => ({
  cursor: "pointer",
  textDecoration: "none",
  color: theme.palette.tests.blue.main,
}));

const SideBar: React.FC<any> = ({ close }: any) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const context = useSelector((state: any) => state.auth.context);
  const home = useSelector((state: any) => state.home);
  const display = useSelector(
    (state: any) => state.user.displayPeModal,
    shallowEqual
  );
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openPeModal, setOpenPeModal] = useState(false);
  const [displayFeedbackIllu, setDisplayFeedbackIllu] = useState(
    document?.documentElement?.clientHeight >
      (context === "collab" || context === "benef" || context === "advisor"
        ? 850
        : 800)
  );

  const handleLinkClick = () => {
    close();
  };

  window.addEventListener("resize", function (event) {
    setDisplayFeedbackIllu(
      document?.documentElement?.clientHeight >
        (context === "collab" || context === "benef" || context === "advisor"
          ? 850
          : 800)
    );
  });

  const displayPeModal = () => {
    setTimeout(() => {
      setOpenPeModal(true);
    }, 90000);
  };

  useEffect(() => {
    display && displayPeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display]);

  return (
    <>
      <Box
        id="drawer"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link
          to={`/app/${context === "candidate" ? "profile/overview" : "home"}`}
        >
          <LogoContainer sx={{ backgroundColor: home?.logo?.background }}>
            <Logo
              src={home?.logo?.contentUrl ?? "/static/logo.svg"}
              alt="logo entreprise"
            />
          </LogoContainer>
        </Link>
      </Box>
      {/* <Divider /> */}
      {context === "collab" ||
      context === "benef" ||
      context === "candidate" ? (
        <CollabContextMenu handleLinkClick={handleLinkClick} />
      ) : context === "advisor" ? (
        <AdvisorContextMenu handleLinkClick={handleLinkClick} />
      ) : context === "recruiter" ? (
        <RecruiterContextMenu handleLinkClick={handleLinkClick} />
      ) : context === "admin" ? (
        <AdminContextMenu handleLinkClick={handleLinkClick} />
      ) : context === "rh" || context === "manager" ? (
        <RHContextMenu handleLinkClick={handleLinkClick} />
      ) : (
        <></>
      )}
      <Box
        className={isGom ? "feedback" : ""}
        sx={{
          padding: "20px",
          marginTop: "auto",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          bottom: 90,
          left: 0,
          right: 0,
        }}
      >
        {displayFeedbackIllu && isGom && (
          <>
            <Typography sx={{ mb: 1, mt: "auto" }} variant="h5">
              {t("side_bar.feedback_title")}
            </Typography>
            <Typography
              color="secondary"
              sx={{
                width: "182px",
                color: context === "advisor" ? "white !important" : "",
              }}
              variant="body2"
            >
              {t("side_bar.text_feedback")}
            </Typography>
            <img
              style={{
                marginTop: "20px",
                height: "110px",
                width: "auto",
                marginBottom: !isGom ? "22px" : "15px",
              }}
              src="/static/icons/home/help.svg"
              alt="help illustration"
            />
          </>
        )}
        <ContactButton onClick={() => setOpenFeedback(true)}>
          <Typography variant="body2"> {t("side_bar.feedback_btn")}</Typography>
        </ContactButton>
      </Box>
      <FeedbackModal open={openFeedback} close={() => setOpenFeedback(false)} />
      {display && (
        <FeedbackPeModal
          open={openPeModal}
          close={() => {
            dispatch(setUserPartial({ displayPeModal: false }));
            setOpenPeModal(false);
          }}
        />
      )}
      <Typography variant="body2" sx={{ m: "auto 16px 16px 32px" }}>
        {t("footer.all_rights", { year: new Date().getFullYear() })}
        <CguLink to="/cgu" target="_blank">
          {t("footer.cgu")}
        </CguLink>
        {t("footer.contact")}
        <MailtoLink
          href={`mailto:${
            home?.paramjson?.supportMail ?? "contact@monkey-tie.com"
          }`}
        >
          {home?.paramjson?.supportMail ?? "contact@monkey-tie.com"}
        </MailtoLink>
      </Typography>
    </>
  );
};

export default SideBar;
