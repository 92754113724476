import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/useFetch";
import RecruitersAPI from "../../../_api/_recruitersAPI";
import AdminInviteUserModal from "../../_admin/params/users/AdminInviteUserModal";
import EditIcon from "@mui/icons-material/Edit";
import AdminUserRoleModal from "../../_admin/params/users/AdminUserRoleModal";
import AdminAdvisorInvitedList from "./AdminAdvisorInvitedList";
import { useSelector } from "react-redux";
import StringAvatar from "../../common/StringAvatar";
import AdminSelectRecruiter from "./AdminSelectRecruiter";
import axios from "axios";
import DeleteAdvisorButton from "./DeleteAdvisorButton";
import { getNumericDate } from "../../../_utils/getNumericDate";

const AdminAdvisorList = ({ displayVisibleRecruiters }: any) => {
  const { t } = useTranslation("common");
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [reloadCall, setReloadCall] = useState(0);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openModalRoleUser, setOpenModalRoleUser] = useState(false);
  const [allRecruiters, setAllRecruiters] = useState([]);
  const [reloadList, setReloadList] = useState(0);
  const { data, loading } = useFetch(RecruitersAPI.get({ page, reloadList }));
  const context = useSelector((state: any) => state.auth.context);

  const handleVisibility = async (people: any, recruiters: String[]) => {
    try {
      await RecruitersAPI.put(people.id, {
        parameters: { ...people.parameters, visible_recruiters: recruiters },
      });
    } catch (err) {}
  };

  useEffect(() => {
    data?.["hydra:member"] && setList(data?.["hydra:member"]);
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(RecruitersAPI.get({ pagination: false }));
      setAllRecruiters(res?.data?.["hydra:member"]);
    };
    fetchData();
  }, []);

  return (
    <div>
      <Box display="flex" alignItems="center">
        <Typography variant="h4">
          {t(`admin.params.right_access.title`)}
        </Typography>
        <Button
          sx={{ ml: "auto", width: "150px", height: "40px" }}
          variant="contained"
          onClick={() => setOpenInviteModal(true)}
        >
          <Typography variant="body2">
            {t(`admin.params.right_access.invite_${context}`)}
          </Typography>
        </Button>
      </Box>
      <Table sx={{ mt: 4 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">{t(`roles.${context}`)}</TableCell>
            <TableCell align="center">
              {" "}
              {t(`admin.params.right_access.roles`)}
            </TableCell>
            <TableCell align="center">
              {t(`admin.params.right_access.mail`)}
            </TableCell>
            {displayVisibleRecruiters && (
              <TableCell align="center">
                {t(`admin.params.right_access.see_${context}`)}
              </TableCell>
            )}
            <TableCell align="center" sx={{ lineHeight: "13px" }}>
              {t("admin.params.right_access.register_date")}
            </TableCell>
            <TableCell align="center">{t("delete")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list?.map((people: any) => (
            <TableRow
              key={people.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                height: 50,
              }}
            >
              <TableCell component="th" scope="row">
                <Box display="flex" alignItems="center">
                  <StringAvatar
                    size={35}
                    name={people?.firstName + " " + people?.lastName}
                    url={people?.person?.image?.contentUrl}
                  />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {`${people.firstName} ${people.lastName}`}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" flexWrap="wrap" justifyContent="center">
                  {people?.roles?.map((role: string, index: number) => (
                    <div key={role}>
                      {t(
                        role.substring(0, 5) === "ROLE_"
                          ? `roles.${role
                              .substring(5, role?.length)
                              .toLowerCase()}`
                          : ""
                      )}
                      {role.substring(0, 5) === "ROLE_" &&
                      index + 1 !== people?.roles?.length
                        ? " / "
                        : ""}
                    </div>
                  ))}
                  <EditIcon
                    onClick={() => {
                      setSelectedUser([people]);
                      setOpenModalRoleUser(true);
                    }}
                    color="primary"
                    sx={{ fontSize: "18px", cursor: "pointer", ml: "auto" }}
                  />
                </Box>
              </TableCell>
              <TableCell>{people.email}</TableCell>
              {displayVisibleRecruiters && (
                <TableCell>
                  <AdminSelectRecruiter
                    items={allRecruiters}
                    recruiter={people}
                    onChange={(e: any) => handleVisibility(people, e)}
                    preSelected={people?.parameters?.visible_recruiters}
                  />
                </TableCell>
              )}
              <TableCell>
                {getNumericDate(new Date(people.person.createdAt))}
              </TableCell>
              <TableCell>
                <DeleteAdvisorButton
                  personId={people.person["@id"].slice(12)}
                  fullName={people.fullName}
                  recruiterId={people.id}
                  reload={setReloadList}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!loading && data && data?.["hydra:totalItems"] > 10 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data?.["hydra:totalItems"]}
          rowsPerPage={10}
          page={page - 1}
          onPageChange={(event: any, newPage: number) => setPage(newPage + 1)}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <AdminAdvisorInvitedList reload={reloadCall} context={context} />
      <AdminInviteUserModal
        open={openInviteModal}
        close={() => setOpenInviteModal(false)}
        reloadCall={() => setReloadCall(reloadCall + 1)}
        showRoles
      />
      <AdminUserRoleModal
        open={openModalRoleUser}
        reloadCall={() => setReloadCall(reloadCall + 1)}
        close={() => {
          setSelectedUser([]);
          setOpenModalRoleUser(false);
        }}
        users={selectedUser}
      />
    </div>
  );
};

export default AdminAdvisorList;
