import { Navigate, useRoutes } from "react-router-dom";
import { lazy, Suspense } from "react";
import LogoLoader from "../components/LogoLoader";
import FullLayout from "../layout/FullLayout";
import NoMenuLayout from "../layout/NoMenuLayout";

import ProfileContextProvider from "../contexts/ProfileContext";
import AdminParamsContextProvider from "./../contexts/AdminParamsContext";
import AccountParamsPage from "../pages/AccountParamsPage";
import MatchingPersonCampaignPage from "../pages/MatchingPersonCampaignPage";
import CguPage from "../pages/CguPage";
import RessourcesPage from "../pages/RessourcesPage";
import { useSelector } from "react-redux";
import AdminAdvisorParamsPage from "../pages/AdminAdvisorParamsPage";
const FoldersPage = lazy(() => import("../pages/FoldersPage"));
const BenefPage = lazy(() => import("../pages/BenefPage"));
const FolderPage = lazy(() => import("../pages/FolderPage"));
const NotFound = lazy(() => import("../pages/NotFound"));
const HomeRecruiter = lazy(() => import("../pages/HomeRecruiter"));
const OnboardingPage = lazy(() => import("../pages/OnboardingPage"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const DeactivateReminder = lazy(() => import("../pages/DeactivateReminder"));

// ----------------------------------------------------------------------

export default function Router() {
  const menu = useSelector((state: any) => state.menu);

  const baseRedirect =
    sessionStorage.getItem("disconnected-path") ?? "/app/home";

  return useRoutes([
    {
      path: "login",
      element: <Navigate to={baseRedirect} replace />,
    },
    {
      path: "app",
      element: <FullLayout />,
      children: [
        { path: "", element: <Navigate to="/app/home" replace /> },
        {
          path: "home",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <HomeRecruiter />
            </Suspense>
          ),
        },
        {
          path: "campaign",
          children: [
            { path: "", element: <Navigate to="/app/campaign/list" /> },
            {
              path: "list",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <FoldersPage />
                </Suspense>
              ),
            },
            {
              path: ":id",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <FolderPage />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "candidate",
          children: [
            { path: "", element: <Navigate to="/app/candidate/list" /> },
            {
              path: "list",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <BenefPage />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "profile/:id/:tab",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfileContextProvider>
                <ProfilePage />
              </ProfileContextProvider>
            </Suspense>
          ),
        },
        {
          path: "matching/:id/:campaignId",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfileContextProvider>
                <MatchingPersonCampaignPage />
              </ProfileContextProvider>
            </Suspense>
          ),
        },
        menu?.settings && {
          path: "account",
          children: [
            {
              path: "",
              element: <Navigate to="/app/account/params" replace />,
            },
            {
              path: "params",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <AccountParamsPage />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "params/:tab",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <AdminParamsContextProvider>
                <AdminAdvisorParamsPage />
              </AdminParamsContextProvider>
            </Suspense>
          ),
        },
        menu?.resources && {
          path: "resources",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <RessourcesPage />
            </Suspense>
          ),
        },
      ].filter((el: any) => el),
    },
    {
      path: "",
      element: <NoMenuLayout />,
      children: [
        {
          path: "onboarding",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfileContextProvider>
                <OnboardingPage />
              </ProfileContextProvider>
            </Suspense>
          ),
        },
        {
          path: "cgu",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <CguPage />
            </Suspense>
          ),
        },
        {
          path: "deactivate_reminder",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <DeactivateReminder />
            </Suspense>
          ),
        },
        {
          path: "404",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <NotFound />
            </Suspense>
          ),
        },
        { path: "/", element: <Navigate to="/app" replace /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
