import React, { useState } from "react";
import useFetch from "../../../hooks/useFetch";
import StatsAPI from "../../../_api/_statsAPI";
// import FeedbacksList from "../../admin_params/FeedbacksList";
import StatsAdmin from "../graph/StatsAdmin";
import AdminRecruiterCredits from "../recruiter/AdminRecruiterCredits";

const AdminAdvisorHome: React.FC = () => {
  const [httpParams, setHttpParams] = useState<any>({});

  const { data, loading } = useFetch(StatsAPI.getById("data", httpParams));

  return (
    <>
      <AdminRecruiterCredits />
      <br />

      <StatsAdmin
        data={data?.data}
        loading={loading}
        httpParams={httpParams}
        setHttpParams={setHttpParams}
      />

      {/* <FeedbacksList /> */}
    </>
  );
};

export default AdminAdvisorHome;
