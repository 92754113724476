import { Box, Fade, styled, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";
import Big5Result from "../big5/Big5Result";
import CulturefitResult from "../culturefit/CulturefitResult";
import { ProfileContext } from "../../contexts/ProfileContext";
import IncResult from "../inc/IncResult";
import ProfileSectionsHeader from "./ProfileSectionsHeader";
import MoodResults from "../mood/results/MoodResults";

const Subtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.grey[500],
}));

const TestsSection = ({ notCollab, data, loading }: any) => {
  const { t } = useTranslation("common");
  const context = useContext(ProfileContext);

  const hasResultTest = (code: string) => {
    return data?.["hydra:member"].filter((el: any) => el.code === code)[0]
      ?.isPassed;
  };

  const returnResultTest = (code: string) => {
    return data?.["hydra:member"].filter((el: any) => el.code === code)[0];
  };

  const handleResults = (res: any) => {
    const resu = res;
    const keys = Object.keys(resu);
    const arr: any[] = [];
    keys.forEach((el: any) =>
      arr.push({
        key: el,
        ...resu[el],
      })
    );
    return arr;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Fade in={true} timeout={500}>
          <div>
            <Box sx={{ mb: 2 }}>
              <ProfileSectionsHeader
                title={t("profilepage.tests")}
                subtitle={
                  data?.["hydra:member"].filter((el: any) => el.isPassed)
                    .length > 0
                    ? t(`profilepage.tests_section.count`, {
                        count: data?.["hydra:member"].filter(
                          (el: any) => el.isPassed
                        ).length,
                        name: context.fullName,
                      })
                    : ""
                }
                text=""
                icon={
                  <img
                    src="/static/icons/home/tests.svg"
                    alt="tests icon"
                    color="primary"
                  />
                }
                notCollab
              />
            </Box>
            {data?.["hydra:member"].filter((el: any) => el.isPassed).length ===
              0 && (
              <Box sx={{ mt: 5, textAlign: "center", width: "100%" }}>
                <Subtitle>
                  {t(`profilepage.tests_section.empty`, {
                    name: context.fullName,
                  })}
                </Subtitle>
              </Box>
            )}
            {hasResultTest("b5") && (
              <Box id="Big5-ctn">
                <Big5Result
                  results={returnResultTest("b5")?.results}
                  traits={returnResultTest("b5")?.results?.traits}
                  result={returnResultTest("b5")}
                  notCollab={notCollab}
                />
              </Box>
            )}
            {hasResultTest("cf") && (
              <Box sx={{ mt: 2 }} id="CultureFit-ctn">
                <CulturefitResult
                  result={returnResultTest("cf")}
                  notCollab={notCollab}
                />
              </Box>
            )}

            {hasResultTest("inc") && (
              <Box sx={{ mt: 2 }} id="Inc-ctn">
                <IncResult
                  person={context.peopleData}
                  result={returnResultTest("inc")?.results?.results}
                  notCollab={notCollab}
                />
              </Box>
            )}

            {hasResultTest("mood") && (
              <Box sx={{ mt: 2 }} id="Inc-ctn">
                <MoodResults
                  mainUser={false}
                  results={handleResults(
                    returnResultTest("mood")?.results.results
                  )}
                />
              </Box>
            )}
          </div>
        </Fade>
      )}
    </>
  );
};

export default TestsSection;
