import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import DownloadPdfButton from "../common/DownloadPdfButton";
import { Stack, styled, useTheme } from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const DownloadButton = styled(Button)(({ theme }: any) => ({
  backgroundColor: theme.palette.grey[500_8],
  color: theme.palette.primary.main,
  fontSize: "12px",
  fontWeight: "normal",
}));

const DownloadMenu: React.FC<any> = ({
  notCollab,
  profileData,
  hasB5orDynamics,
}) => {
  const { t } = useTranslation("common");

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const theme: any = useTheme();

  return (
    <>
      <DownloadButton aria-describedby={id} onClick={handleClick}>
        <FileDownloadOutlined color="primary" sx={{ fontSize: "22px" }} />
        {t("downloads")}
      </DownloadButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack spacing={1}>
          <DownloadPdfButton
            notCollab={notCollab}
            type="selection"
            person={profileData}
            backgroundColor={theme.palette.grey[500_8]}
          />
          <DownloadPdfButton
            notCollab={notCollab}
            type="cv"
            person={profileData}
            backgroundColor={theme.palette.grey[500_8]}
          />
          {hasB5orDynamics && (
            <DownloadPdfButton
              notCollab={notCollab}
              type="synthesis"
              person={profileData}
              backgroundColor={theme.palette.grey[500_8]}
            />
          )}
        </Stack>
      </Popover>
    </>
  );
};

export default DownloadMenu;
