import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  TablePagination,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/useFetch";
import invitationsAPI from "../../../_api/_invitationsAPI";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import axios from "axios";
import { toast } from "react-toastify";
import StringAvatar from "../../common/StringAvatar";
import { getNumericDate } from "../../../_utils/getNumericDate";

const AdminAdvisorInvitedList = ({ reload, context }: any) => {
  const { t } = useTranslation("common");
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);

  const { data, loading } = useFetch(
    invitationsAPI.get({
      page,
      context: `ROLE_ADMIN${context === "recruiter" ? "RECRUITER" : "ADVISOR"}`,
      reload,
    })
  );

  useEffect(() => {
    data?.["hydra:member"] && setList(data?.["hydra:member"]);
  }, [data]);

  const handleRemind = async (people: any) => {
    try {
      const res = await axios.get(
        invitationsAPI.getById(`${people.id}/send-reminder`)
      );
      if (
        res.data.emailStatus === "failed" ||
        res.data.emailStatus === "blocked"
      ) {
        toast.error(
          t("advisor.benef.invite_alert.remind_error", {
            name: people?.firstName + " " + people?.lastName,
          })
        );
      } else if (
        res.data.emailStatus === "sent" ||
        res.data.emailStatus === "pending"
      ) {
        toast.success(t("advisor.benef.invite_alert.sent"));
      }
    } catch (err: any) {
      toast.error(
        err?.status === 412
          ? t("advisor.benef.invite_alert.remind_error", {
              name: people?.firstName + " " + people?.lastName,
            })
          : t("errors.error_append")
      );
    }
  };

  return (
    <div>
      {data?.["hydra:totalItems"] > 0 && (
        <Table sx={{ mt: 4 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {t(`admin.params.right_access.invited_${context}`)}
              </TableCell>
              <TableCell align="center">
                {t("admin.params.right_access.roles")}
              </TableCell>
              <TableCell align="center">
                {t("admin.params.right_access.mail")}
              </TableCell>
              <TableCell align="center">
                {t("admin.params.right_access.mail_status")}
              </TableCell>
              <TableCell align="center">
                {t("admin.params.right_access.invite_date")}
              </TableCell>
              <TableCell align="center">{t("btn.remind")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((people: any) => (
              <TableRow
                key={people.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  height: 50,
                }}
              >
                <TableCell component="th" scope="row">
                  <Box display="flex" alignItems="center">
                    <StringAvatar
                      size={35}
                      name={people?.firstName + " " + people?.lastName}
                    />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {`${people.firstName} ${people.lastName}`}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display="flex" flexWrap="wrap" justifyContent="center">
                    {people.roles.map((role: string, index: number) => (
                      <div key={role}>
                        {t(
                          role.substring(0, 5) === "ROLE_"
                            ? `roles.${role
                                .substring(5, role?.length)
                                .toLowerCase()}`
                            : ""
                        )}
                        {role.substring(0, 5) === "ROLE_" &&
                        index + 1 !== people?.roles?.length
                          ? " / "
                          : ""}
                      </div>
                    ))}
                  </Box>
                </TableCell>
                <TableCell>{people.email}</TableCell>
                <TableCell>
                  {t(`advisor.benef.email_status.${people.emailStatus}`)}{" "}
                  {people.reminderSentAt && <>({t("revived")})</>}
                </TableCell>
                <TableCell>
                  {people.reminderSentAt || people.sendDate
                    ? getNumericDate(
                        new Date(people.reminderSentAt ?? people.sendDate)
                      )
                    : "-"}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleRemind(people)}
                    aria-label="send remind invitation"
                  >
                    <NotificationsActiveIcon color="primary" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!loading && data && data?.["hydra:totalItems"] > 10 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data?.["hydra:totalItems"]}
          rowsPerPage={10}
          page={page - 1}
          onPageChange={(event: any, newPage: number) => setPage(newPage + 1)}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default AdminAdvisorInvitedList;
