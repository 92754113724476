import { Box, Button, Collapse, Typography, Zoom } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Big5DistributionItem from "./Big5DistributionItem";
import ValorDistributionItem from "./ValorDistributionItem";

const CollabDistribution: React.FC<{ data: any }> = ({ data }) => {
  const { t } = useTranslation("common");
  const [showValor, setShowValor] = useState(false);
  const valors = [
    "remuAvt",
    "formEvo",
    "recoMgt",
    "exp",
    "respCha",
    "devEga",
    "creaInno",
    "teamAmb",
  ];
  const b5Traits = [
    "openness",
    "conscientiousness",
    "extraversion",
    "agreeableness",
    "neuroticism",
  ];

  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "center", width: "100%", m: 2 }}
      >
        <Button
          variant={!showValor ? "contained" : "outlined"}
          onClick={() => setShowValor(false)}
          size="large"
          sx={{ borderRadius: "7px 0 0 7px" }}
        >
          Personnalité
        </Button>
        <Button
          variant={showValor ? "contained" : "outlined"}
          onClick={() => setShowValor(true)}
          size="large"
          sx={{ borderRadius: "0 7px 7px 0" }}
        >
          Valeurs
        </Button>
      </Box>
      <Collapse in={true} timeout="auto" unmountOnExit>
        <Zoom in={true}>
          <Box>
            <Typography variant="subtitle1">
              {t(
                `admin.graph.${showValor ? "valor" : "personnality"}.subtitle`,
                {
                  count: showValor ? data.cf.cnt : data.b5.cnt,
                  s: (showValor ? data.cf.cnt : data.b5.cnt) > 1 ? "s" : "",
                }
              )}
            </Typography>
            <Typography variant="body1">
              {t(`admin.graph.${showValor ? "valor" : "personnality"}.summary`)}
            </Typography>
            {showValor
              ? valors.map((valor: string, key: number) => (
                  <ValorDistributionItem
                    type={valor.toLocaleLowerCase()}
                    values={data.cf[valor]}
                    key={key}
                  />
                ))
              : b5Traits.map((trait: any, key: number) => (
                  <Big5DistributionItem
                    type={trait}
                    values={data.b5[trait]}
                    key={key}
                  />
                ))}
          </Box>
        </Zoom>
      </Collapse>
    </>
  );
};

export default CollabDistribution;
