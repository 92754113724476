import React, { useState, useContext } from "react";
import Page from "../components/Page";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AdminParamsContext } from "../contexts/AdminParamsContext";
import { CardContent, Container, Paper } from "@mui/material";
import AdminAdvisorHome from "../components/admin/advisor/AdminAdvisorHome";
import AdminAdvisorParams from "../components/admin/advisor/AdminAdvisorParams";
import AdminTabAnchor from "../components/_admin/AdminTabAnchor";
import AdminDisplayCommon from "../components/admin/AdminDisplayCommon";
import { useSelector } from "react-redux";
import AdminAdvisorBenefs from "../components/admin/advisor/AdminAdvisorBenefs";

const AdminAdvisorParamsPage = () => {
  const { t } = useTranslation("common");
  const { tab } = useParams();
  const context = useSelector((state: any) => state.auth.context);
  const [current, setCurrent] = useState(
    tab === "home" ? 0 : tab === "common" ? 1 : tab === context ? 2 : 0
  );

  const { params } = useContext(AdminParamsContext);

  const components: any = [
    <AdminAdvisorHome />,
    <AdminDisplayCommon />,
    <AdminAdvisorParams />,
    <AdminAdvisorBenefs />,
  ];

  return (
    <Page
      title={t("admin.title")}
      sx={{ pt: 12, pb: 5, width: { md: "calc(100vw - 270px)" } }}
    >
      <AdminTabAnchor current={current} setCurrent={setCurrent} />
      <Container>
        <Paper
          elevation={3}
          sx={{
            ml: { xs: 0, md: "155px" },
            width: "auto",
            borderRadius: "23px",
          }}
        >
          <CardContent>
            <Paper>{params && components?.[current]}</Paper>
          </CardContent>
        </Paper>
      </Container>
    </Page>
  );
};

export default AdminAdvisorParamsPage;
