import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import useFetch from "../../../../hooks/useFetch";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";

const AdvisorAutocomplete: React.FC<any> = ({
  sendDataToParent,
  disabled = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly any[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [needle, setNeedle] = React.useState("");
  const [value, setValue] = React.useState<any>(null);
  const { t } = useTranslation("common");

  const { data, loading } = useFetch(
    `recruiters?pagination=false${needle ? `&term=${needle}` : ""}`
  );

  React.useEffect(() => {
    const arr = (data?.["hydra:member"] ?? []).map((el: any) => ({
      title: el.fullName,
      index: el.id,
    }));
    setOptions([...arr]);
  }, [data]);

  React.useEffect(() => {
    let to: any;

    // if (inputValue.length < 2) {
    //   // setOptions([]);
    //   return;
    // }

    to = setTimeout(() => {
      if (inputValue !== needle) setOptions([]);
      setNeedle(inputValue);
    }, 300);

    return () => {
      if (to) clearTimeout(to);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  const handleChange = (e: any, value: any) => {
    setValue({ title: "" });
    setInputValue("");
    sendDataToParent(value);
  };

  return (
    <Autocomplete
      id="asynchronous-advisor"
      disabled={disabled}
      loadingText={t("autocomplete.loading")}
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      freeSolo
      disableClearable
      blurOnSelect
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={loading}
      value={value}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("btn.search")}
          value={inputValue}
          onChange={(event: any) => {
            setInputValue(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : inputValue ? (
                  <IconButton
                    aria-label="clear"
                    onClick={() => {
                      setInputValue("");
                      setValue({ title: "" });
                      setOptions([]);
                    }}
                    edge="end"
                  >
                    <CloseIcon sx={{ fontSize: "20px" }} />
                  </IconButton>
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AdvisorAutocomplete;
