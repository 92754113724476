import { Player } from "@lottiefiles/react-lottie-player";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  styled,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../_utils/colors";
import LongText from "../../LongText";

const Root = styled(Card)(({ theme }: any) => ({
  height: "100%",
  "&:hover $badge": {
    transform: "scale(1)",
  },
  borderRadius: theme.shape.borderRadius,
  overflow: "visible",
  position: "relative",
  boxShadow: theme.customShadows.default,
  border: `.6px solid #E6E6E6`,
}));

const AccessCardContent = styled(CardContent)(({ theme }: any) => ({
  display: "flex",
  height: "100%",
  alignItems: "center",
  padding: theme.spacing(2),
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
  },
}));

const BoxIcon: any = styled(Box, {
  shouldForwardProp: (props) => props !== "color" && props !== "index",
})(({ theme, color, index }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor:
    color === "#FFA300"
      ? color
      : theme.palette[color ? color : colors[index]][500],
  padding: "13px 10px",
  borderRadius: theme.shape.borderRadius10,
  flexShrink: 0,
}));

const Badge = styled(Box)(({ theme }: any) => ({
  transform: "scale(0)",
  transition: "transform 0.1s linear",
  boxShadow: theme.customShadows.z8,
  background: theme.palette.common.white,
  width: 50,
  height: 50,
  borderRadius: "50%",
  position: "absolute",
  top: -25,
  right: 35,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  userSelect: "none",
}));

const AccessCard: React.FC<{
  badgeNumber?: string | number;
  title: string;
  subtitle: any;
  index?: number;
  iconLink?: string;
  link?: string;
  color?: string;
  error?: boolean;
  success?: boolean;
}> = ({
  badgeNumber,
  title,
  subtitle,
  index = 0,
  link,
  iconLink,
  color,
  error,
  success,
}) => {
  const navigate = useNavigate();

  let lottieRef: any;

  const theme: any = useTheme();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);
  return (
    <Root
      onClick={() => (link ? navigate(link) : null)}
      onMouseEnter={() => lottieRef.play()}
      onMouseLeave={() => lottieRef.stop()}
    >
      <CardActionArea sx={{ borderRadius: "7px", height: "100%" }}>
        <AccessCardContent>
          <BoxIcon sx={{ mr: 2 }} color={color} index={index}>
            {/* {iconLink && <img src={iconLink} alt="icon" />} */}
            {iconLink && mounted && (
              <Player
                ref={(x) => (lottieRef = x)}
                loop
                src={iconLink}
                style={{ height: "42px", width: "42px" }}
              ></Player>
            )}
          </BoxIcon>
          <Box>
            <Typography variant="subtitle1">{title}</Typography>
            {typeof subtitle === "string" ? (
              <LongText
                variant="body2"
                maxChar={65}
                color={
                  error
                    ? theme.palette.error[500_80]
                    : success
                    ? theme.palette.green[500_80]
                    : theme.palette.secondary.light
                }
                text={subtitle}
                lineHeight="15px"
              />
            ) : (
              subtitle
            )}
          </Box>
        </AccessCardContent>
      </CardActionArea>

      {String(badgeNumber)?.length > 0 && badgeNumber !== undefined && (
        <Badge>
          <Typography variant="body2" fontWeight="bold">
            {typeof badgeNumber === "number" && badgeNumber > 99
              ? "99+"
              : badgeNumber}
          </Typography>
        </Badge>
      )}
    </Root>
  );
};
export default AccessCard;
