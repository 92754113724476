import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import HomeIcon from "../../icons/menu/home";
import ProfileIcon from "../../icons/menu/profile";
import TestIcon from "../../icons/menu/test";
import NavigationRenderer from "../NavigationRenderer";
import ResourcesMenuIcon from "../../components/ressources/ResourcesMenuIcon";

const RecruiterContextMenu: React.FC<{
  handleLinkClick: any;
}> = ({ handleLinkClick }: any) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const context = useSelector((state: any) => state.auth.context);
  const menu = useSelector((state: any) => state.menu);
  const links = [
    {
      icon: (
        <HomeIcon
          active={location.pathname.includes("home")}
          context={context}
          size={25}
        />
      ),
      label: t("home"),
      path: "/app/home",
      active: location.pathname.includes("home"),
    },
    {
      label: t("recruiter.campaign.page"),
      icon: (
        <TestIcon
          active={location.pathname.includes("campaign")}
          context={context}
          size={25}
        />
      ),
      path: "/app/campaign/list",
      active: location.pathname.includes("campaign"),
    },
    {
      label: t("recruiter.candidate.candidates"),
      icon: (
        <ProfileIcon
          active={location.pathname.includes("candidate")}
          context={context}
          size={25}
        />
      ),
      path: "/app/candidate/list",
      active: location.pathname.includes("candidate"),
    },
    menu?.resources && {
      icon: <ResourcesMenuIcon />,
      label: t("resources.title"),
      path: "/app/resources",
      active: location.pathname.includes("resources"),
    },
  ].filter((el: any) => el);
  return <NavigationRenderer links={links} handleLinkClick={handleLinkClick} />;
};

export default RecruiterContextMenu;
