import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import GenderSelect from "../common/GenderSelect";
import ProfilePictureButton from "../common/ProfilePictureButton";
import { useTranslation } from "react-i18next";
import VisioList from "./VisioList";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ControlledTextField from "../forms/ControlledTextField";
import CustomButtonModal from "../common/CustomBtnModal";
import { useSelector } from "react-redux";

interface IFormInput {
  gender: string;
  image: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  streetAddress: string;
  addressLocality: string;
  addressCountry: string;
  zoom: string;
  teams: string;
  linkedin: string;
}

const ProfileEditFormBody: React.FC<any> = ({
  countriesList,
  form,
  submit,
  loading,
  close,
}) => {
  const { t } = useTranslation("common");

  const { handleSubmit, control, getValues } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: form,
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    submit(data);
  };

  const disableEditName = useSelector(
    (state: any) => state?.home?.paramjson?.disableEditName
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack sx={{ "& .MuiGrid-root": { pr: 0 } }}>
        {/* Photo profil */}
        <ProfilePictureButton image={getValues("image")} />
        <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
          <Controller
            name="gender"
            control={control}
            rules={{
              required: { value: true, message: "errors.required" },
            }}
            render={({ field: { value, onChange } }) => (
              <GenderSelect gender={value} setGender={onChange} littleMargin />
            )}
          />
        </Box>
        <Grid container sx={{ marginTop: "0px !important", pr: 1 }}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ p: 1 }}>
              <ControlledTextField
                name="firstName"
                label={t("form.firstname")}
                control={control}
                disabled={disableEditName}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ p: 1 }}>
              <ControlledTextField
                name="lastName"
                label={t("form.lastname")}
                control={control}
                disabled={disableEditName}
              />
            </Box>
          </Grid>
        </Grid>
        {/* Address */}
        <Box sx={{ p: 1 }}>
          <ControlledTextField
            name="streetAddress"
            label={t("form.address")}
            control={control}
          />
        </Box>
        <Grid container sx={{ marginTop: "0px !important", pr: 1 }}>
          <Grid item xs={12} sm={3}>
            <Box sx={{ p: 1 }}>
              <ControlledTextField
                name="postalCode"
                label={t("form.postal_code")}
                control={control}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box sx={{ p: 1 }}>
              <ControlledTextField
                name="addressLocality"
                label={t("form.city")}
                control={control}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl sx={{ m: 1, width: "-webkit-fill-available" }}>
              <InputLabel id="demo-simple-select-label">
                {t("form.country")}
              </InputLabel>
              <Controller
                control={control}
                name="addressCountry"
                render={({ field: { onChange, value } }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={t("form.country")}
                    onChange={(e: any) => onChange(e.target.value)}
                  >
                    <MenuItem key={"null"} value="">
                      {t("none")}
                    </MenuItem>
                    {countriesList.map((el: any) => (
                      <MenuItem key={el.id} value={el.id}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          {/* Moyen de visio disponible */}
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
              {t("profilepage.visios.subtitle")}
            </Typography>
          </Grid>
          <VisioList control={control} />
        </Grid>

        <Typography variant="caption" sx={{ pl: 1 }}>
          {t("profilepage.add_address_text")}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="center"
          sx={{ mt: 3 }}
        >
          <CustomButtonModal
            disabled={loading}
            type="submit"
            variant="contained"
            text={t("btn.save")}
          />
          <CustomButtonModal
            secondary
            onClick={close}
            variant="outlined"
            color="secondary"
            text={t(`btn.cancel`)}
          />
        </Box>
      </Stack>
    </form>
  );
};

export default ProfileEditFormBody;
