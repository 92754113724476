import { Box, ButtonBase, styled, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { setIcon } from "../../../_utils/testsIcons";
import { useTranslation } from "react-i18next";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import peopleAPI from "../../../_api/_people";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RemindTestModal from "./RemindTestModal";
import { toast } from "react-toastify";

const Badge: any = styled(ButtonBase, {
  shouldForwardProp: (props) => props !== "type",
})(({ theme, type }: any) => ({
  background:
    type === "Inc" || type === "gamified"
      ? theme.palette.purple[500_12]
      : type === "mood"
      ? theme.palette.orange[500_16]
      : type === "Big5"
      ? theme.palette.primary[500_12]
      : type === "CultureFit"
      ? theme.palette.red[500_12]
      : theme.palette.grey[500_8],
  padding: theme.spacing(1.4),
}));

const TestBadge: React.FC<{
  type: string;
  background?: string;
  todo?: boolean;
  noMargin?: boolean;
  disableTooltip?: boolean;
  size?: number;
  iconSize?: number;
  padding?: number;
  askTest?: boolean;
  remakeTest?: boolean;
  person?: any;
  tabChange?: any;
  inFolder?: boolean;
  disableClick?: boolean;
}> = ({
  type,
  background,
  todo = false,
  noMargin,
  disableTooltip,
  size,
  padding,
  iconSize,
  askTest,
  remakeTest,
  person,
  tabChange,
  inFolder,
  disableClick = false,
}) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [remindType, setRemindType] = useState("");
  const context = useSelector((state: any) => state.auth.context);
  let title = type;
  const types: Array<{ type: string; translation: string }> = [
    { type: "skills", translation: t("tests.skills") },
    { type: "Big5", translation: t("tests.b5") },
    {
      type: "CultureFit",
      translation: t("tests.cf"),
    },
    { type: "Inc", translation: t("tests.inc") },
    { type: "gamified", translation: t("tests.ptw") },
  ];
  const index = types.map((el) => el.type).indexOf(type);
  if (index !== -1) {
    title = types[index].translation;
  }

  const handleAskTest = async () => {
    try {
      await peopleAPI.patch(`${person?.id}/send-notification`, {
        [remindType]:
          type === "CultureFit"
            ? "cf"
            : type === "Big5"
            ? "big5"
            : type === "Inc"
            ? "inc"
            : type === "mood"
            ? "mood"
            : "",
      });
      setOpen(false);
      setRemindType("");
      toast.success(t("success.request_sent"));
    } catch (err) {
      toast.error(t("errors.error_append"));
    }
  };

  return (
    <>
      <Tooltip title={!disableTooltip ? title : ""}>
        <Box
          display="flex"
          onClick={() => {
            if (!disableClick) {
              if (context === "recruiter" || context === "advisor") {
                if (askTest && todo) {
                  setRemindType("askNew");
                } else if (remakeTest && !todo) {
                  setRemindType("remake");
                } else {
                  tabChange?.(type);
                }
                ((askTest && todo) || (remakeTest && !todo)) && setOpen(true);
              } else {
                type === "skills" ? tabChange?.(type) : navigate("/app/tests");
              }
            }
          }}
        >
          <Badge
            sx={{
              zIndex: 1,
              width: size ?? 40,
              height: size ?? 40,
              minWidth: size ?? 40,
              minHeight: size ?? 40,
              borderRadius: "50%",
              opacity: todo ? 0.4 : 1,
              padding:
                typeof padding === "number"
                  ? `${padding}px !important`
                  : "auto",
              background: background ?? "auto",
              mr: noMargin ? 0 : 1,
            }}
            type={index !== -1 && types[index].type}
          >
            <img
              style={
                type === "gamified"
                  ? { width: 40, borderRadius: "50%" }
                  : { width: "auto", height: iconSize ?? 30 }
              }
              alt={type + " Icon"}
              src={setIcon(type)}
            />
          </Badge>
          {askTest &&
            todo &&
            (context === "recruiter" || context === "advisor") && (
              <Box sx={{ position: "relative", zIndex: 1 }}>
                <AddCircleIcon
                  color="error"
                  sx={{
                    position: "absolute",
                    cursor: "pointer",
                    fontSize: "15px",
                    bottom: 0,
                    right: 10,
                    borderRadius: "50%",
                    boxShadow: "4px 6px 12px #FF778788",
                  }}
                />
              </Box>
            )}
          {!todo &&
            (remakeTest
              ? (context === "recruiter" || context === "advisor") && (
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: 10,
                      backgroundColor: "white",
                    }}
                  >
                    <RestartAltIcon
                      color="primary"
                      sx={{
                        position: "absolute",
                        cursor: "pointer",
                        fontSize: "15px",
                        bottom: 0,
                        right: 7,
                        borderRadius: "50%",
                        boxShadow: "4px 6px 12px #94A9FF",
                      }}
                    />
                  </Box>
                )
              : !inFolder &&
                (context === "recruiter" || context === "advisor") && (
                  <Box sx={{ position: "relative", zIndex: 1 }}>
                    <CheckCircleIcon
                      color="success"
                      sx={{
                        position: "absolute",
                        cursor: "pointer",
                        fontSize: type === "gamified" ? "16px" : "15px",
                        bottom: 0,
                        right: type === "gamified" ? 8 : 10,
                        borderRadius: "50%",
                        backgroundColor: "common.white",
                        boxShadow: "4px 6px 12px #8CFAC788",
                      }}
                    />
                  </Box>
                ))}
        </Box>
      </Tooltip>
      <RemindTestModal
        open={open}
        close={() => setOpen(false)}
        handleAskTest={handleAskTest}
        person={person}
        remindType={remindType}
        type={type}
      />
    </>
  );
};

export default TestBadge;
