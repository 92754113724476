import { useTheme } from "@mui/material";
import React from "react";

const LogoutIcon: React.FC<{
  context?: string;
  menu?: boolean;
  size?: number;
}> = ({ context, menu, size }) => {
  const theme: any = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? "13.477"}
      height={size ?? "13.432"}
      viewBox="0 0 13.477 13.432"
    >
      <g id="_008-logout" data-name="008-logout" transform="translate(0 -0.85)">
        <g
          id="Groupe_3075"
          data-name="Groupe 3075"
          transform="translate(0 0.85)"
        >
          <g
            id="Groupe_3074"
            data-name="Groupe 3074"
            transform="translate(0 0)"
          >
            <path
              id="Tracé_12552"
              data-name="Tracé 12552"
              d="M6.716,13.163H1.679a.56.56,0,0,1-.56-.56V2.529a.56.56,0,0,1,.56-.56H6.716a.56.56,0,0,0,0-1.119H1.679A1.681,1.681,0,0,0,0,2.529V12.6a1.681,1.681,0,0,0,1.679,1.679H6.716a.56.56,0,0,0,0-1.119Z"
              transform="translate(0 -0.85)"
              fill={
                context === "collab" ||
                context === "benef" ||
                context === "candidate" ||
                menu
                  ? theme.palette.contextColors.collab.font
                  : "#fff"
              }
            />
          </g>
        </g>
        <g
          id="Groupe_3077"
          data-name="Groupe 3077"
          transform="translate(4.477 3.648)"
        >
          <g id="Groupe_3076" data-name="Groupe 3076">
            <path
              id="Tracé_12553"
              data-name="Tracé 12553"
              d="M178.933,110.684l-3.4-3.358a.56.56,0,0,0-.786.8l2.432,2.4H170.66a.56.56,0,0,0,0,1.119h6.516l-2.432,2.4a.56.56,0,1,0,.786.8l3.4-3.358a.559.559,0,0,0,0-.8Z"
              transform="translate(-170.1 -107.165)"
              fill={
                context === "collab" ||
                context === "benef" ||
                context === "candidate" ||
                menu
                  ? theme.palette.contextColors.collab.font
                  : "#fff"
              }
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoutIcon;
