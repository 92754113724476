import React, { useContext } from "react";
import AdminRecruiterDisplay from "./recruiter/AdminRecruiterDisplay";
import { AdminParamsContext } from "../../contexts/AdminParamsContext";
import AdminSaveParamsButton from "../_admin/AdminSaveParamsButton";
import { Divider } from "@mui/material";
import AdminRecruiterTests from "./recruiter/AdminRecruiterTests";

const AdminDisplayCommon = () => {
  const { parametersFetch, editParams } = useContext(AdminParamsContext);

  console.log(parametersFetch);

  const params = parametersFetch?.data?.["hydra:member"]?.find(
    (el: any) => el.type === "RECRUITER_PARAMS"
  );

  const handleChanges = (p: any, key: string) => {
    const cp = { ...params };
    cp.parameters[key] = p;

    editParams(cp, "RECRUITER_PARAMS");
  };

  const obj = {
    param: params?.parameters,
    handleChanges,
  };

  return (
    <div>
      <AdminRecruiterDisplay
        param={obj.param.DISPLAY}
        handleChanges={(p: any) => handleChanges(p, "DISPLAY")}
      />

      <Divider sx={{ mt: 3, mb: 3 }} />
      <AdminRecruiterTests
        param={obj.param.DEFAULT_CHECK}
        handleChanges={(p: any) => handleChanges(p, "DEFAULT_CHECK")}
      />

      <AdminSaveParamsButton type={"RECRUITER_PARAMS"} additionalCall />
    </div>
  );
};

export default AdminDisplayCommon;
