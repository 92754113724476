import { CardContent, Container, Paper } from "@mui/material";
import React, { useContext, useState } from "react";
import Page from "../components/Page";
import AdminDisplayParams from "../components/_admin/params/AdminDisplayParams";
import AdminGeneralParams from "../components/_admin/params/AdminGeneralParams";
import AdminNotificationsParams from "../components/_admin/params/AdminNotificationsParams";
import AdminWorksParams from "../components/_admin/params/AdminWorksParams";
import { AdminParamsContext } from "../contexts/AdminParamsContext";
import { useParams } from "react-router-dom";
import AdminTabAnchor from "../components/_admin/AdminTabAnchor";
import { useTranslation } from "react-i18next";
import ParamsPageSkeleton from "../components/skeletons/admin/ParamsPageSkeleton";
import AdminWidgetConfigurator from "../components/_admin/widget/AdminWidgetConfigurator";

const AdminParamsPage: React.FC<any> = () => {
  const { t } = useTranslation("common");
  const { tab } = useParams();
  const [current, setCurrent] = useState(
    tab === "general"
      ? 0
      : tab === "display"
      ? 1
      : tab === "notif"
      ? 2
      : tab === "works"
      ? 3
      : 0
  );

  const { loading, params } = useContext(AdminParamsContext);

  // const sendParams = (param: any) => {
  //   console.log("param", param);
  //   if (!param || !params) return;

  //   const copyParams = [...params];
  //   const index = copyParams.findIndex((el: any) => el.type === param.type);
  //   copyParams[index] = param;
  // };

  // useEffect(() => {
  //   if (params) console.log("PARAMS ARE SET");
  // }, [params]);

  // useEffect(() => {
  //   if (data?.["hydra:member"]) setParams(data["hydra:member"]);
  // }, [data]);

  const components: any = params
    ? [
        <AdminGeneralParams />,
        <AdminDisplayParams />,
        <AdminNotificationsParams />,
        <AdminWorksParams />,
        <AdminWidgetConfigurator />,
      ]
    : null;

  return (
    <Page title={t("admin.title")} sx={{ pt: 12, pb: 5 }}>
      <AdminTabAnchor current={current} setCurrent={setCurrent} />
      <Container>
        <Paper
          elevation={3}
          sx={{
            ml: { xs: 0, md: "155px" },
            width: "auto",
            borderRadius: "23px",
          }}
        >
          <CardContent>
            <Paper>
              {loading ? (
                <ParamsPageSkeleton />
              ) : (
                params && components?.[current]
              )}
            </Paper>
          </CardContent>
        </Paper>
      </Container>
    </Page>
  );
};

export default AdminParamsPage;
