import {
  Box,
  Button,
  Pagination,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useFetch from "../../hooks/useFetch";
import Loader from "../../Loader";
import { Api } from "../../_api/Api.class";
import SkillEdit from "../skills/SkillEdit";

const CustomButton = styled(Button)`
  font-size: 13px;
  font-weight: 300;
  padding: 15px 35px;
  width: 325px;
  box-shadow: none;
  &.MuiButton-outlined {
    color: #8f9bb3;
    border-color: #8f9bb3;
  }
`;

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontSize: "19px",
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontSize: "17px",
}));

const ExperienceSkillsForm: React.FC<any> = ({ work, baseLevel, onSubmit }) => {
  const { t } = useTranslation("common");

  const [skills, setSkills] = useState([]);
  const [page, setPage] = useState(1);
  const person_id = useSelector((state: any) => state.user.person_id);

  const api = new Api(`work_skills`);
  const { data, loading, error } = useFetch(
    api.get({ "person.id": person_id, pagination: false, work: work["@id"] })
  );

  const totalItems = skills.length;
  const itemsPerPage = 5;

  useEffect(() => {
    if (!loading && data?.["hydra:member"]?.length === 0) {
      onSubmit([]);
    }
  }, [loading, data, onSubmit]);

  useEffect(() => {
    if (Math.ceil(skills.length / itemsPerPage) < page && page > 1) {
      setPage((c) => c - 1);
    }
  }, [page, skills]);

  useEffect(() => {
    if (error) {
      toast.error(t("errors.error_append"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const confirm = () => {
    onSubmit(skills);
  };

  useEffect(() => {
    const dataSkills = data?.["hydra:member"] ?? [];

    switch (baseLevel) {
      case 1:
        dataSkills.forEach((el: any) => (el.level.level = 1));
        break;
      case 2:
        dataSkills.forEach(
          (el: any) =>
            (el.level.level = el.level.level > 1 ? el.level.level - 1 : 1)
        );
        break;
      case 4:
        dataSkills.forEach(
          (el: any) =>
            (el.level.level = el.level.level < 4 ? el.level.level + 1 : 4)
        );
        break;
    }

    setSkills(dataSkills);
  }, [data, baseLevel]);

  const deleteSkill = (skill: any) => {
    setSkills((current) => current.filter((s: any) => s.id !== skill.id));
  };

  const updateSkill = (skill: any) => {
    setSkills((current: any) => {
      const sks: any = [...current];
      const index = current.findIndex((s: any) => s.id === skill.id);
      sks[index] = skill;
      return sks;
    });
  };

  return (
    <div>
      {loading || data?.["hydra:member"]?.length === 0 ? (
        <Loader />
      ) : (
        <div>
          <div>
            <Title textAlign="center">
              {t("experience_form.skills_suggest")}{" "}
              <Typography
                fontSize="19px"
                component="span"
                color="primary"
                fontWeight="700"
              >
                {work.label}
              </Typography>
            </Title>

            <SubTitle textAlign="center">
              {t("experience_form.from_your_profile")}{" "}
              <Typography
                fontSize="17px"
                component="span"
                color="primary"
                fontWeight="700"
              >
                {t(`experience_form.profile_${baseLevel}`)}
              </Typography>
              {t("experience_form.profile_your_profile_2")}
            </SubTitle>

            <Stack marginTop={2} spacing={2} id="skills-ctn">
              {skills
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((skill: any) => (
                  <SkillEdit
                    key={skill.id}
                    skill={{
                      ...skill,
                      label: skill?.label ?? skill?.skill?.label,
                    }}
                    skillEmitter={updateSkill}
                    canRemove={true}
                    deleteEmitter={deleteSkill}
                  />
                ))}
            </Stack>

            {!loading && skills.length === 0 && (
              <Typography textAlign="center">
                {t("skills.no_suggests")}
              </Typography>
            )}
          </div>

          <Box display="center" justifyContent="center" sx={{ mt: 2 }}>
            <Pagination
              count={Math.ceil(totalItems / itemsPerPage)}
              page={page}
              onChange={(event, value) => setPage(value)}
            />
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <CustomButton
              variant="contained"
              color="primary"
              sx={{ mb: 2, mt: 2 }}
              onClick={confirm}
            >
              {t("skills.btn_valid")}
            </CustomButton>

            <CustomButton onClick={() => onSubmit([])} variant="outlined">
              {t("skills.btn_no_add")}
            </CustomButton>
          </Box>
        </div>
      )}
    </div>
  );
};

export default ExperienceSkillsForm;
