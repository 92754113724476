import { Box, Typography, Grid, Zoom, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AccessCard from "../_collab/home/AccessCard";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { ProfileContext } from "../../contexts/ProfileContext";
import useFetch from "../../hooks/useFetch";
import peopleAPI from "../../_api/_people";
import AccessCardSkeleton from "../skeletons/collab/AccessCardSkeleton";

const Subtitle: any = styled(Typography, {
  shouldForwardProp: (props) => props !== "error",
})(({ theme, error }: any) => ({
  color: error ? theme.palette.error[500_80] : theme.palette.secondary[500_70],
}));

const MainInfosAccessCards: React.FC<{
  changeTab?: any;
  notCollab?: boolean;
  tabs: string[];
}> = ({ changeTab, notCollab, tabs }) => {
  const { id } = useParams();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const context = useSelector((state: any) => state.auth?.context);
  const { fullName, peopleData } = useContext(ProfileContext);
  const [infos, setInfos] = useState<any>();

  const hasSkills =
    ((context === "candidate" || context === "recruiter") &&
      peopleData?.onboardingTest.includes("skills")) ||
    (context !== "candidate" && context !== "recruiter");
  const hasJob =
    ((context === "candidate" || context === "recruiter") &&
      peopleData?.onboardingTest.includes("job")) ||
    (context !== "candidate" && context !== "recruiter");

  const { data, loading } = useFetch(
    peopleAPI.getById(`${id ?? tokenData.person_id}/completeness`)
  );

  useEffect(() => {
    data && setInfos(data?.completenessOfProfile);
  }, [data]);

  return loading ? (
    <div>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {Array.from(Array(4)).map((el, i: number) => (
          <Zoom in={true} key={i}>
            <Grid item xs={12} md={6}>
              <AccessCardSkeleton withIconLink={true} withSubtitle={true} />
            </Grid>
          </Zoom>
        ))}
      </Grid>
    </div>
  ) : (
    <div>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Zoom in={true}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            onClick={() =>
              notCollab
                ? changeTab(tabs.findIndex((el) => el === "tests"))
                : navigate("/app/tests")
            }
          >
            <AccessCard
              index={0}
              badgeNumber={`${infos?.pTest}%`}
              title={t("profilepage.test", { count: infos?.nbrDoneTest })}
              iconLink="/static/illustrations/lotties/tests.json"
              color="primary"
              subtitle={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Subtitle error={infos?.nbrTodoTest > 0} variant="caption">
                    {t(
                      infos?.nbrTodoTest === 0
                        ? `completeness${
                            notCollab ? ".not_collab" : ""
                          }.test_completed`
                        : `completeness${notCollab ? ".not_collab" : ""}.${
                            infos?.nbrTodoTest > 0 ? "count_test" : "test"
                          }`,
                      {
                        count: infos?.nbrTodoTest,
                        name: notCollab && fullName,
                      }
                    )}
                  </Subtitle>
                </Box>
              }
            />
          </Grid>
        </Zoom>

        {hasJob && (
          <Zoom in={true} style={{ transitionDelay: "100ms" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              onClick={() =>
                changeTab(tabs.findIndex((el) => el === "experiences"))
              }
            >
              <AccessCard
                index={1}
                badgeNumber={`${infos?.pExp}%`}
                title={t("experience_section.label", { count: infos?.nbExp })}
                iconLink="/static/illustrations/lotties/experience.json"
                color="green"
                subtitle={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Subtitle error={infos?.nbExp < 2} variant="caption">
                      {t(
                        infos?.nbExp >= 2
                          ? `completeness${
                              notCollab ? ".not_collab" : ""
                            }.exp_completed`
                          : `completeness${notCollab ? ".not_collab" : ""}.${
                              infos?.nbExp < 2 ? "count_exp" : "exp"
                            }`,
                        {
                          count:
                            infos?.nbExp < 2 ? 2 - infos?.nbExp : infos?.nbExp,

                          name: notCollab && fullName,
                        }
                      )}
                    </Subtitle>
                  </Box>
                }
              />
            </Grid>
          </Zoom>
        )}

        {hasSkills && (
          <Zoom in={true} style={{ transitionDelay: "200ms" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              onClick={() => changeTab(tabs.findIndex((el) => el === "skills"))}
            >
              <AccessCard
                index={2}
                badgeNumber={`${infos?.pSkill}%`}
                title={t("profilepage.skills_count", {
                  count: infos?.nbSkills,
                })}
                iconLink="/static/illustrations/lotties/skills.json"
                color="purple"
                subtitle={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Subtitle error={infos?.nbSkills < 5} variant="caption">
                      {t(
                        infos?.nbSkills >= 5
                          ? `completeness${
                              notCollab ? ".not_collab" : ""
                            }.skills_completed`
                          : `completeness${notCollab ? ".not_collab" : ""}.${
                              infos?.nbSkills < 5 ? "count_skills" : "skills"
                            }`,
                        {
                          count:
                            infos?.nbSkills >= 5
                              ? infos?.nbSkills
                              : 5 - infos?.nbSkills,
                          name: notCollab ? fullName : "",
                        }
                      )}
                    </Subtitle>
                  </Box>
                }
              />
            </Grid>
          </Zoom>
        )}

        {hasSkills && (
          <Zoom in={true} style={{ transitionDelay: "200ms" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              onClick={() =>
                changeTab(tabs.findIndex((el) => el === "aptitudes"))
              }
            >
              <AccessCard
                index={2}
                badgeNumber={`${infos?.pSkill}%`}
                title={t("profilepage.aptitude_count", {
                  count: infos?.nbSkillsTransversal,
                })}
                iconLink="/static/illustrations/lotties/abilities.json"
                color="#FFA300"
                subtitle={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Subtitle
                      error={infos?.nbSkillsTransversal < 5}
                      variant="caption"
                    >
                      {t(
                        `completeness${notCollab ? ".not_collab" : ""}.${
                          infos?.nbSkillsTransversal >= 5
                            ? "aptitude_completed"
                            : "count_aptitude"
                        }`,
                        {
                          count:
                            infos?.nbSkillsTransversal < 5
                              ? 5 - infos?.nbSkillsTransversal
                              : infos?.nbSkillsTransversal,
                          name: notCollab && fullName,
                        }
                      )}
                    </Subtitle>
                  </Box>
                }
              />
            </Grid>
          </Zoom>
        )}
      </Grid>
    </div>
  );
};

export default MainInfosAccessCards;
