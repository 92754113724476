import { Collapse, Zoom } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const MultipleColumnChart: React.FC<{
  data?: any[];
  height?: number;
  type?: string;
}> = ({ data, height = 200, type }) => {
  const [series, setSeries] = useState<any[]>([{}]);

  const { t } = useTranslation("common");

  const chartParams: any = {
    options: {
      plotOptions: {
        bar: {
          borderRadius: 8,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          return `<div style="padding: 6px;">
          <span>Nombre de ${
            type === "questionnary" ? "passages" : "créations"
          } :  <b>${series[seriesIndex][dataPointIndex]}</b></span></div>`;
        },
      },
      xaxis: {
        categories: data?.map((month: any) => month.name),
        labels: { style: { fontFamily: "Product Sans" } },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      chart: {
        stacked: true,
      },
    },
  };

  const chart = {
    ...chartParams,
    options: {
      ...chartParams.options,
    },
    series: series,
  };

  useEffect(() => {
    if (data) {
      const seriesToSet = [];
      const size = data[0].series.length;

      for (let i = 0; i < size; i++) {
        seriesToSet.push({
          name:
            type === "questionnary"
              ? t(`graph.${data[0].series[i].name}`)
              : data[0].series[i].name,
          data: data.map((month: any) => month.series[i].value),
        });
      }

      setSeries(seriesToSet);
    }
  }, [data, type, t]);

  return (
    <Collapse in={true} timeout="auto" unmountOnExit>
      <Zoom in={true}>
        <Box>
          {series[0].data && (
            <ReactApexChart
              series={chart.series}
              options={chart.options}
              chart={chart.chart}
              height={height}
              type="bar"
            />
          )}
        </Box>
      </Zoom>
    </Collapse>
  );
};

export default MultipleColumnChart;
