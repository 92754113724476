import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";

import {
  BrowserRouter,
  // Route
} from "react-router-dom";

import { setAxios } from "./axiosConfig";
import { seti18n } from "./i18nInit";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { HelmetProvider } from "react-helmet-async";
import ThemeConfig from "./theme";
import "./form-request-polyfill";
import "./crypto-polyfill";

// Init
seti18n();
setAxios();
// ---

// Disable logs in production mode
if (
  (process.env.REACT_APP_ENV === "prod" ||
    process.env.REACT_APP_ENV === "review") &&
  window
) {
  window.console.log = () => {};
  // window.console.warn = () => {};
  // window.console.error = () => {};
  window.console.table = () => {};
}
// ---

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeConfig>
          <I18nextProvider i18n={i18next}>
            <HelmetProvider>
              <App />
              {/* <Route path="/linkedin" element={<LinkedInCallback />} /> */}
            </HelmetProvider>
          </I18nextProvider>
        </ThemeConfig>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
