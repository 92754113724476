import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IncSquareQuestion from "./IncSquareQuestion";
import { useTranslation } from "react-i18next";

const Inline: any = styled(RadioGroup, {
  shouldForwardProp: (props) => props !== "groupKey",
})(({ groupKey }: any) =>
  groupKey
    ? {
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "row",
        flexWrap: "wrap",
      }
    : {}
);

const FormControlLabelVariant = styled(FormControlLabel)(({ theme }: any) => ({
  background: theme.palette.grey[200],
  borderRadius: "10px",
  paddingRight: theme.spacing(2.1),
  paddingLeft: theme.spacing(1),
  paddingTop: "5px",
  paddingBottom: "5px",
  "&.selected": {
    background: theme.palette.primary[500],
    color: "white",
  },
}));

const IncRadioList: React.FC<any> = ({
  question,
  sendResponse,
  validated,
  mounted,
}) => {
  const [state, setState] = useState(null);
  const groupKey = question?.groupKey?.split("_")?.[0] === "01";
  const { t } = useTranslation("common");

  useEffect(() => {
    if (question?.answers) {
      const e = question.answers.find((el: any) => el.previousValue);
      if (e) {
        setState(e.previousValue);
      }
    }
  }, [question]);

  useEffect(() => {
    let to: any;
    if (state) {
      to = setTimeout(() => {
        sendResponse({
          code: question.code,
          answer: [
            {
              value: state,
              code: question.answers.find((el: any) => el.value === state).code,
            },
          ],
        });
      }, 100);
    }

    return () => {
      if (to) clearTimeout(to);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <FormControl component="fieldset" sx={{ width: "100%", mt: 1 }}>
      {groupKey ? (
        <Inline
          groupKey={groupKey}
          aria-label={question.label}
          name={question.label}
        >
          {question.answers.map((el: any) => (
            <FormControlLabelVariant
              sx={{ m: 1 }}
              className={el.value === state ? "selected" : ""}
              key={el.code}
              value={el.value}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: el.value === state && groupKey ? "white" : "",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: 20,
                    },
                  }}
                  checked={el.value === state}
                  onChange={() => mounted && setState(el.value)}
                />
              }
              label={<span style={{ fontSize: "16px" }}>{el.label}</span>}
            />
          ))}
        </Inline>
      ) : (
        <IncSquareQuestion
          state={state}
          question={question}
          onChange={setState}
        />
      )}
      {validated && !state && (
        <Typography color="error">{t("inc.errors.select_one")}</Typography>
      )}
    </FormControl>
  );
};

export default IncRadioList;
