import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  CircularProgress,
  InputAdornment,
  useTheme,
  Tooltip,
} from "@mui/material";
import useFetch from "../../hooks/useFetch";
import { Api } from "../../_api/Api.class";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, shallowEqual } from "react-redux";

const AutocompleteComponent: React.FC<{
  type: string;
  required?: boolean;
  baseValue?: any;
  onBlur?: (e: any) => void;
  placeholder?: string;
  label?: string;
  limit?: number;
  clearOnSelect?: boolean;
  formError?: boolean;
  formHelper?: string;
  sendDataToParent?: (value: any) => void;
  onFocus?: () => void;
  variant?: "filled" | "standard" | "outlined" | undefined;
  displayIcon?: boolean;
  noRadius?: boolean;
  parentLoading?: boolean;
  skillType?: any;
  multi?: boolean;
  disabled?: boolean;
  companyOnly?: boolean;
  sx?: any;
  updateValue?: any;
}> = ({
  type,
  required,
  variant,
  onBlur,
  sendDataToParent,
  baseValue,
  label,
  placeholder,
  limit = 100,
  clearOnSelect,
  formError,
  onFocus,
  formHelper,
  displayIcon,
  noRadius,
  parentLoading,
  skillType,
  multi,
  disabled,
  companyOnly,
  sx,
  updateValue,
}) => {
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const [api] = useState(new Api(`${type}/autocomplete`));

  const theme: any = useTheme();

  const [needle, setNeedle] = useState(null);

  const [helperText, setHelperText] = useState("");

  const { data, loading, error } = useFetch(
    api.get({
      needle,
      limit,
      ...(skillType && { type: skillType }),
      ...(companyOnly && { company: `/api/companies/${tokenData.company_id}` }),
      ...(type === "skills" ? { version: 2 } : {}),
    }),
    true
  );

  const [value, setValue] = useState<any>(baseValue ?? { label: "" });
  const [options, setOptions] = useState<any>([]);
  const [selectedValues, setSelectedValues] = useState<any>([]);

  const [init, setInit] = useState(true);

  const [inputValue, setInputValue] = useState<any>(
    baseValue ? baseValue.label : ""
  );

  const { t } = useTranslation("common");

  React.useEffect(() => {
    if (!updateValue) {
      setValue(baseValue ?? { label: "" });
    } else setValue(updateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateValue]);

  const handleChange = (event: any, newValue: any) => {
    if (newValue?.label) {
      if (multi) {
        const newArr: any = [...selectedValues];
        newArr.push(newValue.id);
        setSelectedValues(newArr);
        setValue({ label: "" });
        setInputValue("");
        if (sendDataToParent) sendDataToParent(newArr);
      } else {
        if (sendDataToParent) sendDataToParent(newValue);
        if (!clearOnSelect) setValue(newValue);
        else {
          setValue({ label: "" });
          setInputValue("");
        }
      }
    } else {
      if (sendDataToParent) sendDataToParent({ label: "" });
      setValue({ label: "" });
      setInputValue("");
    }
  };

  useEffect(() => {
    if (baseValue && init) {
      setInit(false);
      return;
    }
    const timeout = setTimeout(() => {
      if (inputValue?.length >= 3) {
        search();
      } else {
        // setOptions([]);
        if (inputValue?.length < 3 && inputValue.length > 1) {
          setHelperText("3 caractères min");
        }
      }
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const search = () => {
    setNeedle(inputValue);
    setHelperText("");
  };

  useEffect(() => {
    if (data && data["hydra:member"]) {
      setOptions(data["hydra:member"]);
      if (data["hydra:member"].length === 0 && inputValue?.length >= 3) {
        setHelperText("Aucun résultat");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Autocomplete
      {...{ loading, value, options }}
      id="autocompleteComponent"
      onChange={handleChange}
      freeSolo
      filterOptions={(options) => options}
      multiple={multi}
      disableClearable={inputValue?.length === 0}
      getOptionLabel={(option: any) => option.label || ""}
      loadingText={t("autocomplete.loading")}
      disabled={parentLoading || disabled}
      forcePopupIcon
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          sx={{
            color:
              type === "works" && option?.company
                ? `${theme.palette.primary.main}!important`
                : "inherit",
          }}
        >
          <Tooltip
            title={
              type === "works" && option?.company ? "Métier entreprise" : ""
            }
          >
            <div>
              <span>{option?.label} &nbsp;</span>
              {type === "skills" &&
                option?.skillCategoryData?.labels?.length > 0 && (
                  <span
                    style={{
                      color: option?.skillCategoryData?.colors?.button,
                    }}
                  >
                    {"| "}
                    {option?.skillCategoryData?.labels
                      ?.toReversed?.()
                      ?.join(" > ")}
                  </span>
                )}
            </div>
          </Tooltip>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          {...{ required, variant, placeholder, label }}
          sx={{
            ...sx,
            "& .MuiOutlinedInput-root": {
              borderTopLeftRadius: noRadius ? "0px" : "",
              borderBottomLeftRadius: noRadius ? "0px" : "",
            },
          }}
          value={inputValue}
          error={error || formError}
          onChange={(text) => {
            setInputValue(text.target.value);
          }}
          onFocus={() => (onFocus ? onFocus() : null)}
          onBlur={() => (onBlur ? onBlur(value) : null)}
          helperText={helperText || formHelper}
          disabled={parentLoading || disabled}
          InputProps={{
            ...params.InputProps,
            startAdornment: displayIcon && (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteComponent;
