import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LogoutIcon from "../../icons/menu/logout";
import LogoutModal from "./logoutModal/LogoutModal";

export default function LogoutButton({
  padding,
  context,
  className,
  menu,
  iconSize,
}: any) {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  return (
    <>
      <ListItem
        button
        onClick={() => {
          setOpen(true);
        }}
        className={className}
      >
        <ListItemIcon sx={{ pl: padding }}>
          <LogoutIcon context={context} menu={menu} size={iconSize} />
        </ListItemIcon>
        <ListItemText primary={t("logout.logout")} />
      </ListItem>
      {open && <LogoutModal open={open} close={() => setOpen(false)} />}
    </>
  );
}
