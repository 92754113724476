import { Box, styled, Tooltip, Typography } from "@mui/material";
import palette from "../../../theme/palette";
import React from "react";
import { useTranslation } from "react-i18next";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const Container = styled(Box)(({ theme }: any) => ({
  display: "flex",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid black",
  height: 42,
}));

const Big5DistributionItem: React.FC<{
  type:
    | "openness"
    | "conscientiousness"
    | "extraversion"
    | "agreeableness"
    | "neuroticism";
  values: number[];
}> = ({ type, values }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Typography variant="h4" sx={{ textAlign: "center", mt: 2 }}>
        {t(`big5.traits.${type}.label`)}
        <Tooltip placement="left" title={<>{t(`big5.traits.${type}.help`)}</>}>
          <HelpOutlineOutlinedIcon
            color="primary"
            sx={{ fontSize: "17px", ml: 1 }}
          />
        </Tooltip>
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="subtitle1"
          dangerouslySetInnerHTML={{
            __html: `${t(`big5.traits.${type}.adjectives.left`)}`,
          }}
        />

        <Typography
          variant="subtitle1"
          dangerouslySetInnerHTML={{
            __html: `${t(`big5.traits.${type}.adjectives.right`)}`,
          }}
        />
      </Box>
      <Container display="flex">
        {values?.map((value: number, key: number) => (
          <Box
            key={key}
            sx={{
              flexGrow: 1,
              borderRadius:
                key === 0
                  ? "7px 0 0 7px"
                  : key === values.length - 1
                  ? "0 7px 7px 0 "
                  : 0,
              backgroundColor: `${palette.b5[type]}${Math.floor(
                value * 2.55
              ).toString(16)}`,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1">{value}%</Typography>
          </Box>
        ))}
      </Container>
    </>
  );
};

export default Big5DistributionItem;
