import React, { useState, useEffect } from "react";
import {
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Utils } from "../../Utils";
import { useSelector } from "react-redux";
import WorkSkillFilter from "./work_filter/WorkSkillFilter";
import WorkStudyLevelFilter from "./work_filter/WorkStudyLevelFilter";
import WorkBookmarkFilter from "./work_filter/WorkBookmarkFilter";
import WorkTensionFilter from "./work_filter/WorkTensionFilter";
// import WorkVideoFilter from "./work_filter/WorkVideoFilter";
import useFetch from "../../hooks/useFetch";
import workCategoryAPI from "../../_api/_workCategoryAPI";
import SelectComponent from "../common/SelectComponent";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import useDebounce from "../../hooks/useDebounce";
import { useLocation } from "react-router-dom";
import WorkBlackListFilter from "./work_filter/WorkBlackListFilter";
import WorkMoodFilter from "./work_filter/WorkMoodFilter";
import WorkTagsFilter from "./WorkTagsFilter";
import TestsFilters from "../common/filters/TestsFilters";

const WorkListFilters = ({
  httpParams,
  setHttpParams,
  relaodBookmark,
  setIsSearching,
  setNavigationState,
  loading,
  stateParamsSkill,
  workSuggest,
  benefId,
  stateParams,
  totalItems,
  isCategory,
}: any) => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const tokenData = useSelector((state: any) => state.user);
  const [needle, setNeedle] = useState("");
  const debouncedSearchTerm = useDebounce(needle, 800);
  const location: any = useLocation();
  const [firstTestCall, setFirstTestCall] = useState(true);
  const [categoryListFilter, seetCategoryListFilter] = useState([]);
  const parameters = useSelector(
    (state: any) =>
      state.parameters.find((el: any) => el.type === "GENERAL_PARAMS")
        ?.parameters?.TESTS
  );

  const workSearch = useSelector(
    (state: any) => state?.home?.paramjson?.workSearch
  );

  const [testsData] = useState<any>(null);

  const categoryCall = useFetch(workCategoryAPI.get({ pagination: false }));

  // const handleTestsImport = (data: any) => {
  //   if (data) {
  //     const obj: any = {};
  //     data.forEach((el: any) => {
  //       obj[el.code] = { ...el };
  //     });
  //     setTestsData(obj);
  //   }
  // };

  const handleCategory = (event: any) => {
    const newParams = { ...httpParams };
    newParams.page = 1;
    event
      ? (newParams.category = `/api/work_categories/${event}`)
      : delete newParams.category;
    setHttpParams(newParams);
    setNavigationState(newParams);
  };

  const handleNeedle = (event: any) => {
    const newParams = { ...httpParams };
    newParams.page = 1;
    newParams.needle = event;
    setHttpParams(newParams);
    setIsSearching?.(false);
    setNavigationState(newParams);
  };

  const handleTests = (filters: any) => {
    Utils.setLongCookies(
      `${tokenData.person_id}${
        context === "advisor" || context === "rh" || context === "manager"
          ? "_benef"
          : ""
      }_work_suggest_filters`,
      JSON.stringify(filters)
    );
    const newParams = { ...httpParams };
    newParams.page = firstTestCall ? stateParams?.page || 1 : 1;
    setFirstTestCall(false);
    newParams.testsFilters = JSON.stringify(filters);
    setHttpParams(newParams);
    setNavigationState(newParams);
  };

  const handleSkills = (event: any) => {
    const newParams = { ...httpParams };
    newParams.page = 1;
    newParams.skills = JSON.stringify(event?.ids);
    setHttpParams(newParams);
    setNavigationState(newParams, event?.items ?? []);
  };

  const handleBookmark = (event: any) => {
    const newParams = { ...httpParams };
    newParams.page = 1;
    Utils.setLongCookies(
      `${tokenData.person_id}_work_suggest_bookmark`,
      `${event}`
    );
    newParams.bookmark = event;
    setHttpParams(newParams);
    setNavigationState(newParams);
  };

  const handleMood = (event: any) => {
    const newParams = { ...httpParams };
    newParams.page = 1;
    Utils.setLongCookies(
      `${tokenData.person_id}_work_suggest_mood`,
      JSON.stringify(event)
    );
    newParams.mood = event;
    setHttpParams(newParams);
    setNavigationState(newParams);
  };

  const handleStudyLevel = (event: any) => {
    const newParams = { ...httpParams };
    newParams.page = 1;
    newParams.listStudyLevel = event?.arr;
    if (event?.formattedData?.length > 0) {
      newParams.studyLevel = event?.formattedData;
    } else {
      delete newParams.studyLevel;
    }
    setHttpParams(newParams);
    setNavigationState(newParams);
  };

  const handleSort = (event: any) => {
    const newParams = { ...httpParams };
    event ? (newParams.tension = event) : delete newParams.tension;
    setHttpParams(newParams);
    setNavigationState(newParams);
  };

  // const handleVideo = () => {
  //   const newParams = { ...httpParams };
  //   newParams.video ? delete newParams.video : (newParams.video = true);
  //   setHttpParams(newParams);
  //   setNavigationState(newParams);
  // };

  const handleBlackList = (event: number) => {
    const newParams = { ...httpParams };
    newParams.blackList = event;
    setHttpParams(newParams);
    setNavigationState(newParams);
  };

  const handleCategoryList = () => {
    const newParams = { ...httpParams };
    newParams.depth = httpParams.isCategory ? 2 : 0;
    newParams.parent = "";
    newParams.isCategory = !httpParams.isCategory;
    newParams.page = 1;
    newParams.breadcrumb = [{ label: "Catégories" }];
    setHttpParams(newParams);
    setNavigationState(newParams);
  };

  const handleTags = (event: any) => {
    const newParams = { ...httpParams };
    newParams.page = 1;
    let newFilters: string = "";
    const queryName = "tag[]";
    event?.ids?.forEach((tag: any, i: any) => {
      const tagId = tag.split("/api/tags/")[1];

      newFilters = `${newFilters}&${queryName}=${tagId}`;
    });

    newParams.tagsFilter = newFilters;
    setNavigationState(newParams);
    setHttpParams(newParams);
  };

  useEffect(() => {
    categoryCall?.data?.["hydra:member"] &&
      seetCategoryListFilter(categoryCall?.data?.["hydra:member"]);
  }, [categoryCall]);

  const clearNeedle = (event: any) => {
    setNeedle(event);
    handleNeedle(event);
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleNeedle(debouncedSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    (context === "advisor" || context === "rh" || context === "manager") &&
      !workSuggest &&
      location?.state?.workHttpParams?.needle &&
      setNeedle(location?.state?.workHttpParams?.needle);

    workSuggest &&
      location?.state?.workSuggestHttpParams?.needle &&
      setNeedle(location?.state?.workSuggestHttpParams?.needle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state]);

  return (
    <>
      {!isCategory && (
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          sx={{ mb: 1.5 }}
        >
          {!httpParams?.isCategory && (
            <Box sx={{ width: { xs: "100%", sm: "50%", md: "30%" } }}>
              <SelectComponent
                items={categoryListFilter}
                label={t("autocomplete.categories")}
                icon={<WorkOutlineOutlinedIcon />}
                onChange={handleCategory}
                noRadiusRight
                parentLoading={loading}
                preSelected={httpParams?.category?.split("/")[3]}
              />
            </Box>
          )}
          <Box
            sx={{
              width: { xs: "100%", sm: "50%", md: "70%" },
              mt: { xs: 1, sm: 0 },
            }}
          >
            <TextField
              sx={
                !httpParams?.isCategory
                  ? {
                      "& .MuiOutlinedInput-root": {
                        borderTopLeftRadius: { sm: "0px" },
                        borderBottomLeftRadius: { sm: "0px" },
                      },
                    }
                  : {}
              }
              placeholder={t("autocomplete.placeholder.work")}
              variant="outlined"
              disabled={loading}
              fullWidth={true}
              value={needle}
              onChange={(text) => (
                // eslint-disable-next-line no-sequences
                setNeedle(text.target.value),
                setIsSearching?.(true),
                !text.target.value && handleNeedle("")
              )}
              InputProps={{
                endAdornment: needle && (
                  <React.Fragment>
                    <CloseIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => clearNeedle("")}
                    />
                  </React.Fragment>
                ),
              }}
            />
          </Box>
        </Box>
      )}
      <Box display="flex" alignItems="center" flexWrap="wrap">
        {/* {workSuggest && (
          <WorkTestsFilter
            handleTests={handleTests}
            loading={loading}
            httpParams={httpParams.testsFilters}
            benefId={benefId}
            exportTestsData={handleTestsImport}
          />
        )} */}
        {workSuggest && <TestsFilters submit={handleTests} />}
        {!isCategory && (
          <>
            {!workSearch?.hideLevel && (
              <WorkStudyLevelFilter
                onChange={handleStudyLevel}
                preSelected={httpParams?.listStudyLevel}
              />
            )}
            {!workSearch?.hideTension && (
              <WorkTensionFilter
                handleSort={handleSort}
                preSelected={httpParams?.tension}
              />
            )}
            {/* {!workSearch?.hideVideo && (
              <WorkVideoFilter
                handleVideo={handleVideo}
                httpParams={httpParams}
              />
            )} */}
            {context !== "advisor" &&
              context !== "rh" &&
              context !== "manager" &&
              workSuggest && (
                <WorkBookmarkFilter
                  handleBookmark={handleBookmark}
                  httpParams={httpParams}
                />
              )}

            {context !== "advisor" &&
              context !== "rh" &&
              context !== "manager" && (
                <WorkBlackListFilter
                  handleBlackList={handleBlackList}
                  preSelected={httpParams.blackList}
                  isCategory={isCategory}
                />
              )}
          </>
        )}
        {!isCategory && (
          <WorkSkillFilter
            stateParamsSkill={stateParamsSkill}
            handleSkills={handleSkills}
            loading={loading}
          />
        )}
        {workSuggest && parameters?.mood && (
          <WorkMoodFilter
            testData={testsData?.mood}
            handleMood={handleMood}
            httpParams={httpParams}
            relaodBookmark={relaodBookmark}
            isBenef={
              context !== "advisor" && context !== "rh" && context !== "manager"
            }
          />
        )}
        {context !== "collab" && !isCategory && (
          <WorkTagsFilter handleTags={handleTags} baseValue={httpParams} />
        )}

        <FormControlLabel
          control={
            <Switch
              checked={httpParams?.isCategory}
              onClick={handleCategoryList}
            />
          }
          label={
            <Typography variant="body1">
              {t(`work_suggest.category_list`)}
            </Typography>
          }
          sx={{ ml: "auto" }}
        />
        {totalItems ? (
          <Typography variant="body1" fontWeight="bold">
            {t("filters.results", { count: totalItems })}
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default WorkListFilters;
