import { Box, Button, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Title = styled(Typography)(({ theme }: any) => ({
  fontSize: 10,
  color: theme.palette.secondary.light,
}));

const CustomButton: any = styled(Button, {
  shouldForwardProp: (props) => props !== "selected",
})(({ theme, selected }: any) => ({
  width: "140px",
  fontSize: "14px",
  borderRadius: "18px",
  backgroundColor: selected ? theme.palette.primary.main : "auto",
  color: selected ? "white" : "auto",
  "&:hover": {
    backgroundColor: selected ? theme.palette.primary.main : "auto",
  },
}));

const GenderSelect: React.FC<any> = ({ setGender, gender, littleMargin }) => {
  const { t }: any = useTranslation("common");
  const [value, setValue] = useState<string>(gender);

  const notInclusive = useSelector(
    (state: any) => state?.home?.paramjson?.notInclusive
  );

  const handleGender = (e: string) => {
    setValue(e);
    setGender(e);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Title textAlign="center" variant="body2">
        {t("form.gender_label")}
      </Title>
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        sx={{
          mt: littleMargin ? 0 : "8px !important",
          mb: littleMargin ? 1 : 5,
        }}
      >
        <CustomButton
          sx={{ m: 0.5 }}
          variant="outlined"
          onClick={() => handleGender("M")}
          selected={value === "M"}
        >
          {t("onboarding.form.male")}
        </CustomButton>
        <CustomButton
          sx={{ m: 0.5 }}
          variant="outlined"
          onClick={() => handleGender("F")}
          selected={value === "F"}
        >
          {t("onboarding.form.female")}
        </CustomButton>
        {notInclusive ? null : (
          <CustomButton
            sx={{ m: 0.5 }}
            variant="outlined"
            onClick={() => handleGender("N")}
            selected={value === "N"}
          >
            {t("onboarding.form.no_gender")}
          </CustomButton>
        )}
      </Box>
    </Box>
  );
};

export default GenderSelect;
