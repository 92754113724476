const incResponses = [
  { code: "685", answer: [{ value: "3", code: 2568 }] },
  { code: "686", answer: [{ code: "2571", value: "1" }] },
  { code: "691", answer: [{ value: "7", code: 2590 }] },
  {
    code: "692",
    answer: [
      { code: "2594", value: "3" },
      { code: "2598", value: "7" },
    ],
  },
  { code: "700", answer: [{ value: "3", code: 2620 }] },
  { code: "701", answer: [{ code: "2627", value: "5" }] },
  { code: "707", answer: [{ value: "7", code: 2647 }] },
  { code: "708", answer: [{ code: "2651", value: "3" }] },
  { code: "714", answer: [{ value: "3", code: 2672 }] },
  { code: "715", answer: [{ code: "2680", value: "6" }] },
  { code: "721", answer: [{ value: "10", code: 2700 }] },
  { code: "722", answer: [{ code: "2706", value: "6" }] },
  { code: "728", answer: [{ value: "1", code: 2723 }] },
  { code: "729", answer: [{ code: "2733", value: "7" }] },
  { code: "735", answer: [{ value: "1", code: 2749 }] },
  {
    code: "736",
    answer: [
      { code: "2754", value: "2" },
      { code: "2757", value: "5" },
      { code: "2758", value: "6" },
    ],
  },
  { code: "742", answer: [{ value: "7", code: 2777 }] },
  {
    code: "743",
    answer: [
      { code: "2780", value: "2" },
      { code: "2782", value: "4" },
      { code: "2783", value: "5" },
    ],
  },
  { code: "749", answer: [{ value: "1", code: 2803 }] },
  { code: "747", answer: [{ code: "2796", value: "7" }] },
  { code: "753", answer: [{ value: "0", code: 2816 }] },
  {
    code: "754",
    answer: [
      { code: "2821", value: "1" },
      { code: "2827", value: "7" },
    ],
  },
  { code: "758", answer: [{ value: "7", code: 2840 }] },
  {
    code: "759",
    answer: [
      { code: "2842", value: "1" },
      { code: "2845", value: "4" },
      { code: "2847", value: "6" },
    ],
  },
  { code: "763", answer: [{ value: "10", code: 2862 }] },
  { code: "764", answer: [{ code: "2868", value: "6" }] },
  { code: "768", answer: [{ value: "7", code: 2882 }] },
  {
    code: "769",
    answer: [
      { code: "2885", value: "2" },
      { code: "2887", value: "4" },
      { code: "2889", value: "6" },
    ],
  },
  { code: "773", answer: [{ value: "3", code: 2902 }] },
  { code: "774", answer: [{ code: "2909", value: "5" }] },
  { code: "778", answer: [{ value: "1", code: 2922 }] },
  { code: "779", answer: [{ code: "2927", value: "2" }] },
  { code: "783", answer: [{ value: "10", code: 2946 }] },
  {
    code: "784",
    answer: [
      { code: "2950", value: "4" },
      { code: "2951", value: "5" },
      { code: "2952", value: "6" },
      { code: "2953", value: "7" },
    ],
  },
  { code: "788", answer: [{ value: "7", code: 2966 }] },
  {
    code: "789",
    answer: [
      { code: "2969", value: "2" },
      { code: "2971", value: "4" },
      { code: "2972", value: "5" },
      { code: "2973", value: "6" },
      { code: "2974", value: "7" },
    ],
  },
  { code: "793", answer: [{ value: "0", code: 2984 }] },
  {
    code: "794",
    answer: [
      { code: "2991", value: "3" },
      { code: "2992", value: "4" },
      { code: "2993", value: "5" },
      { code: "2994", value: "6" },
      { code: "2995", value: "7" },
    ],
  },
  { code: "799", answer: [{ value: "10", code: 3016 }] },
  {
    code: "797",
    answer: [
      { code: "3000", value: "1" },
      { code: "3001", value: "2" },
      { code: "3002", value: "3" },
      { code: "3003", value: "4" },
      { code: "3004", value: "5" },
    ],
  },
  { code: "803", answer: [{ value: "7", code: 3029 }] },
  { code: "804", answer: [{ code: "3035", value: "5" }] },
  { code: "808", answer: [{ value: "1", code: 3048 }] },
  { code: "809", answer: [{ code: "3058", value: "7" }] },
  { code: "813", answer: [{ value: "0", code: 3068 }] },
  {
    code: "814",
    answer: [
      { code: "3073", value: "1" },
      { code: "3076", value: "4" },
    ],
  },
  { code: "818", answer: [{ value: "3", code: 3091 }] },
  {
    code: "819",
    answer: [
      { code: "3095", value: "2" },
      { code: "3097", value: "4" },
    ],
  },
  { code: "823", answer: [{ value: "10", code: 3114 }] },
  {
    code: "824",
    answer: [
      { code: "3117", value: "3" },
      { code: "3118", value: "4" },
    ],
  },
  { code: "828", answer: [{ value: "7", code: 3134 }] },
  {
    code: "829",
    answer: [
      { code: "3138", value: "3" },
      { code: "3140", value: "5" },
    ],
  },
  { code: "833", answer: [{ value: "3", code: 3154 }] },
  {
    code: "834",
    answer: [
      { code: "3160", value: "4" },
      { code: "3162", value: "6" },
    ],
  },
  { code: "838", answer: [{ value: "7", code: 3176 }] },
  { code: "839", answer: [{ code: "3181", value: "4" }] },
  { code: "843", answer: [{ value: "10", code: 3198 }] },
  {
    code: "844",
    answer: [
      { code: "3202", value: "4" },
      { code: "3204", value: "6" },
    ],
  },
  { code: "848", answer: [{ value: "3", code: 3217 }] },
  {
    code: "849",
    answer: [
      { code: "3222", value: "3" },
      { code: "3223", value: "4" },
    ],
  },
  { code: "853", answer: [{ value: "7", code: 3239 }] },
  { code: "854", answer: [{ code: "3246", value: "6" }] },
  { code: "857", answer: [{ value: "1", code: 3261 }] },
  {
    code: "855",
    answer: [
      { code: "3249", value: "2" },
      { code: "3251", value: "4" },
    ],
  },
  { code: "857", answer: [{ value: "1", code: 3261 }] },
  {
    code: "855",
    answer: [
      { code: "3249", value: "2" },
      { code: "3251", value: "4" },
    ],
  },
  { code: "659", result: [] },
  { code: "665", answer: [{ value: "2", code: 2545 }] },
  { code: "682", answer: [{ value: "1", code: 2558 }] },
  { code: "689", answer: [{ value: "0", code: 2578 }] },
  { code: "698", answer: [{ value: "3", code: 2612 }] },
  { code: "705", answer: [{ value: "2", code: 2637 }] },
  { code: "711", answer: [{ value: "3", code: 2659 }] },
  { code: "717", answer: [{ value: "2", code: 2684 }] },
  { code: "726", answer: [{ value: "1", code: 2714 }] },
  { code: "733", answer: [{ value: "2", code: 2741 }] },
  { code: "739", answer: [{ value: "3", code: 2768 }] },
  { code: "746", answer: [{ value: "0", code: 2786 }] },
  { code: "750", answer: [{ value: "2", code: 2809 }] },
  { code: "756", answer: [{ value: "1", code: 2829 }] },
  { code: "761", answer: [{ value: "3", code: 2852 }] },
  { code: "766", answer: [{ value: "2", code: 2872 }] },
  { code: "771", answer: [{ value: "0", code: 2891 }] },
  { code: "776", answer: [{ value: "2", code: 2914 }] },
  { code: "781", answer: [{ value: "3", code: 2936 }] },
  { code: "786", answer: [{ value: "1", code: 2955 }] },
  { code: "791", answer: [{ value: "2", code: 2977 }] },
  { code: "796", answer: [{ value: "3", code: 2999 }] },
  { code: "801", answer: [{ value: "0", code: 3017 }] },
  { code: "806", answer: [{ value: "1", code: 3039 }] },
  { code: "811", answer: [{ value: "2", code: 3061 }] },
  { code: "816", answer: [{ value: "3", code: 3083 }] },
  { code: "821", answer: [{ value: "1", code: 3102 }] },
  { code: "826", answer: [{ value: "0", code: 3122 }] },
  { code: "831", answer: [{ value: "2", code: 3145 }] },
  { code: "836", answer: [{ value: "2", code: 3166 }] },
  { code: "840", answer: [{ value: "3", code: 3188 }] },
  { code: "846", answer: [{ value: "1", code: 3207 }] },
  { code: "851", answer: [{ value: "0", code: 3227 }] },
  { code: "851", answer: [{ value: "0", code: 3227 }] },
  { code: "660", result: [] },
  { code: "666", answer: [{ code: 2548, value: "2" }] },
  { code: "681", answer: [{ code: 2556, value: "5" }] },
  { code: "694", answer: [{ code: 2602, value: "4" }] },
  { code: "696", answer: [{ code: 2605, value: "2" }] },
  { code: "703", answer: [{ code: 2631, value: "2" }] },
  {
    code: "712",
    answer: [
      { code: 2663, value: "4" },
      { code: 2661, value: "2" },
    ],
  },
  { code: "719", answer: [{ code: 2689, value: "4" }] },
  {
    code: "724",
    answer: [
      { code: 2710, value: "3" },
      { code: 2711, value: "4" },
    ],
  },
  { code: "731", answer: [{ code: 2736, value: "3" }] },
  { code: "738", answer: [{ code: 2760, value: "1" }] },
  { code: "738", answer: [{ code: 2760, value: "1" }] },
  { code: "661", result: [] },
  { code: "672", result: [{ code: "", value: 5 }] },
  { code: "662", result: [{ code: "", value: 5 }] },
  { code: "669", result: [{ code: "", value: 5 }] },
  { code: "667", result: [{ code: "", value: 5 }] },
  { code: "668", result: [{ code: "", value: 5 }] },
  { code: "670", result: [{ code: "", value: 5 }] },
  { code: "678", result: [{ code: "", value: 5 }] },
  { code: "679", result: [{ code: "", value: 5 }] },
  { code: "677", result: [{ code: "", value: 5 }] },
  { code: "674", result: [{ code: "", value: 5 }] },
  { code: "675", result: [{ code: "", value: 5 }] },
  { code: "676", result: [{ code: "", value: 5 }] },
];

export default incResponses;
