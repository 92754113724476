import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import palette from "../../../theme/palette";

const Container = styled(Box)(({ theme }: any) => ({
  display: "flex",
  borderRadius: theme.shape.borderRadiusSm,
  border: `1px solid ${palette.grey[300]}`,
  height: 32,
}));

const ValorDistributionItem: React.FC<{ type: string; values: any }> = ({
  type,
  values,
}) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Typography
        variant="subtitle1"
        dangerouslySetInnerHTML={{
          __html: `${t(`culturefit.traits.${type}`)}`,
        }}
        sx={{ mt: 2, mb: 1 }}
      />
      <Container display="flex">
        {values?.map((value: number, key: number) => (
          <Box
            key={key}
            sx={{
              flexGrow: 1,
              borderRadius:
                key === 0
                  ? "14px 0 0 14px"
                  : key === values.length - 1
                  ? "0 14px 14px 0 "
                  : 0,
              backgroundColor: `${palette.primary.main}${Math.floor(
                value * 2.55
              ).toString(16)}`,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1">{value}%</Typography>
          </Box>
        ))}
      </Container>
    </>
  );
};

export default ValorDistributionItem;
