import { Box, Divider } from "@mui/material";
import React, { useContext } from "react";
import { AdminParamsContext } from "../../../contexts/AdminParamsContext";
import AdminSaveParamsButton from "../../_admin/AdminSaveParamsButton";
import AdminAdvisorList from "./AdminAdvisorList";
// import AdminAdvisorNotif from "./AdminAdvisorNotif";
// import AdminAdvisorTag from "./AdminAdvisorTag";
import AdminAdvisorVisibility from "./AdminAdvisorVisibility";
import AdminEmail from "../AdminEmail";
import { useSelector } from "react-redux";

const AdminAdvisorParams = () => {
  const { parametersFetch, editParams } = useContext(AdminParamsContext);
  const context = useSelector((state: any) => state.auth.context);

  const params = parametersFetch?.data?.["hydra:member"]?.find(
    (el: any) => el.type === `${context.toUpperCase()}_PARAMS`
  );

  const handleChanges = (p: any, key: string) => {
    const cp = { ...params };
    cp.parameters[key] = p;

    editParams(cp, `${context.toUpperCase()}_PARAMS`);
  };

  const obj = {
    param: params?.parameters,
    handleChanges,
  };
  return (
    <div>
      {params && (
        <Box sx={{ position: "relative" }}>
          <AdminAdvisorList
            displayVisibleRecruiters={obj.param.VISIBILITY.CAMPAIGN === "PERSO"}
          />
          <Divider sx={{ mt: 3, mb: 3 }} />
          <AdminAdvisorVisibility
            context={context}
            param={obj.param.VISIBILITY}
            handleChanges={(p: any) => handleChanges(p, "VISIBILITY")}
          />
          {/* <Divider sx={{ mt: 3, mb: 3 }} />
          <AdminAdvisorNotif
            context="advisor"
            param={obj.param.NOTIF}
            handleChanges={(p: any) => handleChanges(p, "NOTIF")}
          /> */}
          {/* <Divider sx={{ mt: 3, mb: 3 }} />
          <AdminAdvisorTag
            param={obj.param.TAGS}
            handleChanges={(p: any) => handleChanges(p, "TAGS")}
          /> */}
          <Divider sx={{ mt: 3, mb: 3 }} />
          <AdminEmail />
          <AdminSaveParamsButton
            type={`${context.toUpperCase()}_PARAMS;LABELS_PARAMS`}
            additionalCall
          />
        </Box>
      )}
    </div>
  );
};

export default AdminAdvisorParams;
