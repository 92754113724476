import {
  Box,
  Button,
  Divider,
  CircularProgress,
  styled,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { ProfileContext } from "../../contexts/ProfileContext";
import { checkFileExt } from "../../Utils";
import ProfileDocumentItem from "./ProfileDocumentItem";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ProfileSectionsHeader from "./ProfileSectionsHeader";
import { toast } from "react-toastify";

const Subtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.grey[500],
}));

const ProfileDocuments: React.FC<any> = ({ notCollab }) => {
  const { id } = useParams();
  const { t } = useTranslation("common");
  const { peopleData, setPeopleData, fullName } = useContext(ProfileContext);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const context = useSelector((state: any) => state.auth.context);
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const inputRef = useRef<HTMLInputElement | any>(null);
  const [loading, setLoading] = useState(false);
  const handleAddClick = () => {
    inputRef?.current?.click();
  };

  const handleChange = (e: any) => {
    if (checkFileExt(e.target.files[0])) {
      if (e.target.files[0]?.size < 5000000) {
        setSelectedFile(e.target.files[0]);
        inputRef.current.value = null;
      } else {
        toast.error(t("errors.file_too_big"));
      }
    } else {
      toast.error(t("errors.file_not_supported"));
    }
  };

  useEffect(() => {
    if (selectedFile) {
      (async () => {
        setLoading(true);
        try {
          const formData = new FormData();
          formData.append("file", selectedFile);
          formData.append(
            "uriContext",
            `/api/people/${
              context === "advisor" || context === "recruiter"
                ? id
                : tokenData?.person_id
            }`
          );
          formData.append("name", selectedFile?.name ?? "");
          const response = await axios.post("file_media_objects", formData);

          setPeopleData({
            ...peopleData,
            files: [response.data, ...peopleData.files],
          });
        } catch (err) {
          // Erreur lors de l'ajout du document
        } finally {
          setLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  return (
    <div>
      <ProfileSectionsHeader
        title={t("profilepage.my_documents")}
        subtitle={
          !notCollab || (peopleData?.files && peopleData?.files?.length > 0)
            ? t(
                `profilepage.documents_subtitle${
                  notCollab ? "_not_collab" : ""
                }`,
                {
                  count: peopleData?.files?.length || 0,
                  name: fullName,
                }
              )
            : ""
        }
        text={t("profilepage.documents_text")}
        icon={<AttachFileIcon color="primary" />}
        notCollab={notCollab}
      />

      <Box display="flex" justifyContent="center" marginBottom={2}>
        <Button
          onClick={handleAddClick}
          variant="contained"
          sx={{ fontSize: "12px" }}
          disabled={loading}
        >
          {loading && (
            <CircularProgress color="inherit" size={20} sx={{ mr: 1 }} />
          )}
          {t("profilepage.documents_section.add_file")}
        </Button>

        <input
          onChange={handleChange}
          ref={inputRef}
          style={{ display: "none" }}
          type="file"
        />
      </Box>
      <Typography variant="body2" textAlign="center">
        {t("profilepage.documents_section.text_files")}
      </Typography>
      {!peopleData?.files || peopleData?.files?.length === 0 ? (
        notCollab ? (
          <Box sx={{ mt: 5, textAlign: "center", width: "100%" }}>
            <Subtitle>
              {t(`profilepage.documents_section_empty`, {
                name: notCollab && fullName,
              })}
            </Subtitle>
          </Box>
        ) : (
          <Typography textAlign="center" sx={{ mt: 4 }}>
            {t("profilepage.documents_section.no_files")}
          </Typography>
        )
      ) : (
        <Box sx={{ mt: 3 }}>
          {context !== "collab" &&
            peopleData?.files?.filter(
              (el: any) => el.createdPersonId === peopleData.id
            )?.length > 0 && (
              <Typography variant="h5">
                {t(
                  `profilepage.documents_section.added_${
                    context === "advisor"
                      ? "benef"
                      : context === "recruiter"
                      ? "candidate"
                      : "you"
                  }`
                )}
              </Typography>
            )}
          <Box display="flex" flexWrap="wrap">
            {peopleData?.files
              ?.filter((el: any) => el.createdPersonId === peopleData.id)
              ?.map((file: any) => (
                <ProfileDocumentItem key={file["@id"]} file={file} />
              ))}
          </Box>
          {context !== "collab" &&
            peopleData?.files?.filter(
              (el: any) => el.createdPersonId === peopleData.id
            )?.length > 0 &&
            peopleData?.files?.filter(
              (el: any) => el.createdPersonId !== peopleData.id
            )?.length > 0 && (
              <>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Typography variant="h5">
                  {t(
                    `profilepage.documents_section.added_${
                      context === "benef"
                        ? "advisor"
                        : context === "candidate"
                        ? "recruiter"
                        : "you"
                    }`
                  )}
                </Typography>
              </>
            )}
          <Box display="flex" flexWrap="wrap">
            {peopleData?.files
              ?.filter((el: any) => el.createdPersonId !== peopleData.id)
              ?.map((file: any) => (
                <ProfileDocumentItem key={file["@id"]} file={file} />
              ))}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default ProfileDocuments;
