import { Box, Checkbox, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import TestsAPI from "../../_api/_testsAPI";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { returnNameTest } from "../../Utils";

const Label = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
}));

const TestsFilters: React.FC<{
  updateFilters: any;
  loading?: boolean;
  preSelected?: any;
  personId?: string;
  addMotivations?: boolean;
  home?: boolean;
  noMargin?: boolean;
  dataTests?: any;
}> = ({
  updateFilters,
  loading,
  preSelected,
  personId,
  addMotivations,
  home,
  noMargin,
  dataTests,
}) => {
  const { t } = useTranslation("common");
  const [tests, setTests] = useState<any>();
  const theme: any = useTheme();
  let params: any = { matchers: true };
  if (personId) {
    params.personId = personId;
  }
  const [checkboxesChecked, setCheckboxesChecked] = useState<number>(4);

  const { data } = useFetch(TestsAPI.get(params), Boolean(dataTests));

  const initTestsFilter = (values: any) => {
    /**
     * Conserve les motivations si on ajoute l'attribut addMotivations au composannt
     */
    if (
      !addMotivations &&
      values.findIndex((el: any) => el.code === "cf") >= 0
    ) {
      values.splice(
        values.findIndex((el: any) => el.code === "cf"),
        1
      );
    }

    const newArray: any = [];
    const skillsFilters = [
      {
        isPassed: true,
        code: "technical_skills",
        checked: preSelected ? preSelected.includes("technical_skills") : true,
      },
      {
        isPassed: true,
        code: "transversal_skills",
        checked: preSelected
          ? preSelected.includes("transversal_skills")
          : true,
      },
    ];
    skillsFilters.map((skill: any) => newArray.push(skill));
    values?.map(
      (item: any) =>
        item.code !== "mb5" &&
        item.code !== "cf" &&
        newArray.push({
          isPassed: item.isPassed,
          code: item.code,
          checked:
            item.isPassed &&
            (preSelected
              ? preSelected.includes(returnNameTest(item.code))
              : true),
        })
    );
    setTests(newArray);
    checkTestsFilters(newArray);
    setCheckboxesChecked(
      newArray.reduce(
        (sumChecked: number, el: any) => {
          el.checked && sumChecked++;
          return sumChecked;
        },
        [0]
      )
    );
  };

  const checkTest = (item: any, index: number, checked: boolean) => {
    let newArr: any = [...tests];

    newArr[index] = {
      checked: checked,
      isPassed: item.isPassed,
      code: item.code,
    };
    setTests(newArr);
    checkTestsFilters(newArr);
    setCheckboxesChecked(
      newArr.reduce(
        (sumChecked: number, el: any) => {
          el.checked && sumChecked++;
          return sumChecked;
        },
        [0]
      )
    );
  };

  const checkTestsFilters = (array: any) => {
    const newTestsArr: any = [];
    array.map(
      (obj: any) => obj.checked && newTestsArr.push(returnNameTest(obj.code))
    );
    updateFilters(newTestsArr);
  };

  useEffect(() => {
    console.log("dataTests", dataTests);
    data?.["hydra:member"] &&
      initTestsFilter(data["hydra:member"].filter((el: any) => !el.hidden));
    dataTests && initTestsFilter(dataTests.filter((el: any) => !el.hidden));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataTests]);

  return home ? (
    <></>
  ) : (
    <Box marginLeft={noMargin ? 0 : "auto"}>
      <Box display="flex" flexWrap="wrap" flexGrow={1}>
        {tests?.map(
          (test: any, index: number) =>
            test.code !== "cf" && (
              <Box
                key={test.code}
                display="flex"
                alignItems="center"
                marginLeft={noMargin ? 0 : "38px"}
                sx={{ opacity: !test.isPassed ? "0.6" : 1 }}
              >
                <Checkbox
                  icon={
                    <CheckBoxOutlineBlankIcon
                      sx={{ color: theme.palette.secondary.light }}
                    />
                  }
                  disabled={
                    !test.isPassed ||
                    loading ||
                    (checkboxesChecked === 1 && test.checked)
                  }
                  checked={test.checked}
                  onChange={(e) => checkTest(test, index, e.target.checked)}
                  inputProps={{ "aria-label": "decorative checkbox" }}
                />
                <Label variant="body1">{t(`tests.${test.code}`)}</Label>
              </Box>
            )
        )}
      </Box>
    </Box>
  );
};

export default TestsFilters;
