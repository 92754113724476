import { combineReducers } from "redux";

import authReducer from "./authReducer";
import chatReducer from "./chatReducer";
import globalLoaderReducer from "./globalLoaderReducer";
import homeReducer from "./homeReducer";
import menuReducer from "./menuReducer";
import parametersReducer from "./parametersReducer";
import userReducer from "./userReducer";
import ptwPopupReducer from "./ptwPopupReducer";
import dispatchModalReducer from "./dispatchModalReducer";

export const reducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  chat: chatReducer,
  home: homeReducer,
  parameters: parametersReducer,
  globalLoader: globalLoaderReducer,
  menu: menuReducer,
  ptwPopup: ptwPopupReducer,
  dispatchModal: dispatchModalReducer,
});
