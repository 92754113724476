import {
  Box,
  CardHeader,
  Card,
  CardContent,
  Fade,
  Typography,
  CircularProgress,
  styled,
} from "@mui/material";
import { Cancel, Create } from "@mui/icons-material";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { useSelector, shallowEqual } from "react-redux";
import { ProfileContext } from "../../contexts/ProfileContext";
import peopleAPI from "../../_api/_people";
import { QuillStyle } from "../../_utils/quillStyle";
import StyledButton from "../styled/StyledButton";
import MainInfosAccessCards from "./MainInfosAccessCards";
import MainInfoSkills from "./MainInfoSkills";
import MainInfoWork from "./MainInfoWork";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const BioText = styled(Box)(({ theme }: any) => ({
  color: theme.palette.grey[500],
  fontSize: "12px",
}));

const CustomCard = styled(Card)(({ theme }: any) => ({
  boxShadow: "unset",
  border: `1px solid ${theme.palette.secondary[300_20]}`,
  borderRadius: theme.shape.borderRadius10,
}));

const Progress = styled(CircularProgress)({
  position: "absolute",
});

const MainProfileInfos: React.FC<{
  fade?: number;
  changeTab?: any;
  notCollab?: boolean;
  context: string;
  tabs: string[];
}> = ({ fade = 500, changeTab, notCollab, context, tabs }) => {
  const { t } = useTranslation("common");
  const { peopleData, setPeopleData, skills, transversalSkills } =
    useContext(ProfileContext);
  const [editing, setEditing] = useState(false);
  const [desc, setDesc] = useState(peopleData?.description ?? "");
  const [editedDesc, setEditedDesc] = useState(peopleData?.description ?? "");
  const quillRef: any = useRef();
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setEditedDesc(desc);
    setEditing(false);
  };

  const handleSave = () => {
    setEditing(false);
    setDesc(editedDesc);
    let newDesc: any = editedDesc;
    if (quillRef?.current?.getEditor()?.getText()) {
      const length = quillRef.current.getEditor().getText()?.length;
      if (length === 1) {
        setDesc("");
        newDesc = null;
      }
      setLoading(true);
      peopleAPI
        .put(tokenData?.person_id, { description: newDesc })
        .then((res) => {
          setPeopleData({ ...peopleData, description: newDesc });
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Box>
      <Fade in={true} timeout={fade}>
        <div>
          <MainInfosAccessCards
            changeTab={changeTab}
            notCollab={notCollab}
            tabs={tabs}
          />
          <CustomCard>
            <CardHeader
              sx={{ pt: 2 }}
              title={
                <Typography variant="subtitle1">
                  {t(`profilepage.bio${notCollab ? "_not_collab" : ""}`)}
                </Typography>
              }
              action={
                <Box>
                  {editing ? (
                    <>
                      <StyledButton
                        onClick={handleCancel}
                        width={25}
                        height={25}
                        sx={{ mr: 1 }}
                      >
                        <Cancel color="error" sx={{ width: 15, height: 15 }} />
                      </StyledButton>
                      <StyledButton onClick={handleSave} width={25} height={25}>
                        <CheckCircleIcon
                          color="primary"
                          sx={{ width: 15, height: 15 }}
                        />
                      </StyledButton>
                    </>
                  ) : (
                    !notCollab && (
                      <StyledButton
                        onClick={() => (!loading ? setEditing(!editing) : null)}
                        width={25}
                        height={25}
                      >
                        {!loading ? (
                          <Create
                            color="primary"
                            sx={{ width: 18, height: 18 }}
                          />
                        ) : (
                          <Progress size={20} />
                        )}
                      </StyledButton>
                    )
                  )}
                </Box>
              }
            />
            <CardContent sx={{ pt: 2 }}>
              {editing ? (
                <ReactQuill
                  ref={quillRef}
                  theme=""
                  style={QuillStyle}
                  value={editedDesc}
                  placeholder={t("profilepage.add_desc")}
                  onChange={(value: any) => setEditedDesc(value)}
                />
              ) : desc.length > 0 ? (
                <BioText
                  className={`ql-container`}
                  dangerouslySetInnerHTML={{ __html: desc }}
                />
              ) : (
                <Box>
                  <Typography>
                    {t(
                      `profilepage.${
                        notCollab ? "bio_not_specified" : "add_desc"
                      }`
                    )}
                  </Typography>
                </Box>
              )}
            </CardContent>
          </CustomCard>
          <MainInfoSkills
            skills={skills}
            transversalSkills={transversalSkills}
            notCollab={notCollab}
            changeTab={changeTab}
          />
          {context !== "candidate" && context !== "recruiter" && (
            <MainInfoWork notCollab={notCollab} />
          )}
        </div>
      </Fade>
    </Box>
  );
};

export default MainProfileInfos;
