import React from "react";
import AdminAdvisorBenefsList from "./benefsList/AdminAdvisorBenefsList";

const AdminAdvisorBenefs = () => {
  return (
    <div>
      <AdminAdvisorBenefsList />
    </div>
  );
};

export default AdminAdvisorBenefs;
