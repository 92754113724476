import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import CustomTabs from "../CustomTabs";
import MainProfileInfos from "./MainProfileInfos";
import ExperiencesSection from "./ExperiencesSection";
import { useNavigate, useParams } from "react-router-dom";
import HobbiesSection from "./HobbiesSection";
import LicensesSection from "./LicensesSection";
import TestsSection from "./TestsSection";
import ProfileDocuments from "./ProfileDocuments";
import { ProfileContext } from "../../contexts/ProfileContext";
import NewSkillsSection from "../skills/newSkills/NewSkillsSection";

const ProfileContent: React.FC<{
  employeeData?: any;
  testData?: any;
  notCollab: boolean;
  loading?: boolean;
  context: string;
  tabChange: string;
  candidateId: string;
}> = ({
  employeeData,
  notCollab,
  context,
  loading,
  testData,
  tabChange,
  candidateId,
}) => {
  const [tabs, setTabs] = useState([
    "overview",
    ...(notCollab ? ["tests"] : []),
    "experiences",
    "trainings",
    "skills",
    "aptitudes",
    "hobbies",
    "licenses",
    "documents",
  ]);
  const params = useParams();

  const { peopleData } = useContext(ProfileContext);

  const [value, setValue] = useState<any>(
    tabs.findIndex((el: string) => el === params.tab) || 0
  );
  const [redirect, setRedirect] = useState<any>(null);
  const [currentTab, setCurrentTab] = useState<any>(null);
  const navigate = useNavigate();
  const handleChange = (val: number, fromComponent?: boolean) => {
    setValue(val);
    fromComponent && setRedirect(val);
  };

  const isCollab =
    context === "collab" || context === "candidate" || context === "benef";

  useEffect(() => {
    if (
      (context === "candidate" || context === "recruiter") &&
      peopleData?.onboardingTest
    ) {
      setTabs((arr: any) => {
        if (!peopleData?.onboardingTest.includes("skills")) {
          arr = arr.filter(
            (el: string) => el !== "skills" && el !== "aptitudes"
          );
        }

        if (!peopleData?.onboardingTest.includes("job")) {
          arr = arr.filter(
            (el: string) => el !== "trainings" && el !== "experiences"
          );
        }
        return arr;
      });
    }
  }, [peopleData?.onboardingTest, context]);

  const displayTab = () => {
    switch (tabs[value]) {
      case "overview":
        return (
          <MainProfileInfos
            changeTab={(e: number) => handleChange(e, true)}
            notCollab={notCollab}
            context={context}
            tabs={tabs}
          />
        );
      case "experiences":
        return <ExperiencesSection type="experience" notCollab={notCollab} />;
      case "trainings":
        return <ExperiencesSection type="training" notCollab={notCollab} />;
      case "skills":
        // return <SkillsSection type="skills" notCollab={notCollab} />;
        return (
          <NewSkillsSection
            {...(isCollab ? {} : { personId: peopleData.id })}
          />
        );
      case "aptitudes":
        // return <SkillsSection type="transversal" notCollab={notCollab} />;
        return (
          <NewSkillsSection
            {...(isCollab ? {} : { personId: peopleData.id })}
            transversal
          />
        );
      case "tests":
        return (
          <TestsSection
            notCollab={notCollab}
            data={testData}
            loading={loading}
          />
        );
      case "hobbies":
        return (
          <HobbiesSection notCollab={notCollab} employeeData={employeeData} />
        );
      case "licenses":
        return (
          <LicensesSection notCollab={notCollab} employeeData={employeeData} />
        );
      case "documents":
        return <ProfileDocuments notCollab={notCollab} />;
      default:
        return (
          <Box>
            <p>{tabs[value]}</p>
          </Box>
        );
    }
  };

  useEffect(() => {
    setCurrentTab(displayTab());
    navigate(
      `/app/profile/${employeeData ? `${employeeData.id}/` : ""}${tabs[value]}${
        candidateId ? `?candidateId=${candidateId}` : ""
      }`,
      { replace: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Box>
      <Box display="grid">
        <CustomTabs
          value={value}
          onChange={(e: number, force: boolean) => {
            handleChange(force ? e : redirect);
          }}
          tabs={tabs.map((tab: string) => `profilepage.${tab}`)}
          tabChange={tabChange}
        />
      </Box>
      <Box>{currentTab}</Box>
    </Box>
  );
};

export default ProfileContent;
