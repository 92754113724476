import { Button, Typography, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";

const ConnectImage = styled("img")({
  cursor: "pointer",
  transition: "all 0.1s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const LinkCtn = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #4c6fff;
  color: inherit;
  width: 100%;
`;

const PEConnectButton: React.FC<{ link?: boolean }> = ({ link }) => {
  const home = useSelector((state: any) => state.home, shallowEqual);
  const url = home?.check_saml?.url;
  const url2 = home?.check_saml?.url2;
  const { t } = useTranslation("common");

  const handlePeConnect = () => {
    window.location = url;
  };

  const handleLinkPe = () => {
    window.location = url2;
  };

  return (
    <>
      {url && !link && (
        <div onClick={handlePeConnect}>
          <ConnectImage
            alt="pe icon"
            src="/static/logo/france-travail-connect-white.png"
            height="45px"
          />
        </div>
      )}

      {url2 && link && (
        <LinkCtn onClick={handleLinkPe} variant="outlined">
          <Typography fontWeight="bold">{t("pe.retrieve")}</Typography>
          <ConnectImage
            alt="pe icon"
            src="/static/logo/france-travail-connect-white.png"
            height="45px"
          />
        </LinkCtn>
      )}
    </>
  );
};

export default PEConnectButton;
