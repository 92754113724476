import {
  ArrowBackIosNew,
  Close,
  ModeEditOutlineOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Skeleton, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import chatService from "../../services/chat.service";
import CreateConversationAutocomplete from "./CreateConversationAutocomplete";

const Container = styled("div")(({ theme }) => ({
  backgroundColor: "#F4F5F8",
  borderBottom: "1px solid " + theme.palette.grey[400],
  borderRadius: "12px 12px 0 0",
  alignItems: "right",
  padding: "16px 24px",
  display: "flex",
}));

const MobileCloseIcon = styled(IconButton)({
  display: "flex",
  "@media (min-width:600px)": {
    display: "flex",
  },
  "@media (min-width:960px)": {
    display: "none",
  },
});

const Badge = styled(Box)({
  margin: "3px",
  backgroundColor: "#dbe1ff",
  borderRadius: "5px",
  color: "#4c6fff",
  fontWeight: "bold",
  padding: "5px",
  textOverflow: "ellipsis",
});

const RightAlignedIcon = styled(IconButton)({
  marginLeft: "auto",
});

const CloseConvCreationIcon = styled(IconButton)({
  marginLeft: "-10px",
  marginRight: "10px",
});

const PanelHeader: React.FC<{
  creatingConversation: boolean;
  convName: string;
  mobile: any;
  convDetails: any;
  sidePanel: any;
  setSidePanel: React.Dispatch<React.SetStateAction<any>>;
  setACInputValue: React.Dispatch<React.SetStateAction<any>>;
}> = ({
  creatingConversation,
  convName,
  mobile,
  convDetails,
  sidePanel,
  setSidePanel,
  setACInputValue,
}) => {
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const { t } = useTranslation("common");

  const toggleDrawer =
    (anchor: any, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setSidePanel({ ...sidePanel, [anchor]: open });
    };

  const handleAutocompleteChange = (data: any) => {
    setACInputValue(data);
  };

  return (
    <Container>
      {!creatingConversation ? (
        <>
          {convName !== "" ? (
            <>
              <MobileCloseIcon
                onClick={() => mobile.setState(false)}
                aria-label="Back"
              >
                <Close />
              </MobileCloseIcon>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={toggleDrawer("right", true)}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    color: "#939195",
                  }}
                >
                  {t("chat.to")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    marginLeft: "5px",
                  }}
                >
                  {convDetails?.members?.map((member: any) => {
                    if (member?.id !== tokenData.person_id) {
                      return <Badge key={member.id}>{member?.fullName}</Badge>;
                    }
                    // eslint-disable-next-line array-callback-return
                    return;
                  })}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexGrow: 1,
                }}
              >
                <RightAlignedIcon
                  onClick={toggleDrawer("right", true)}
                  aria-label="Edit"
                >
                  <ModeEditOutlineOutlined />
                </RightAlignedIcon>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Skeleton sx={{ width: "80%" }} />
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <CloseConvCreationIcon
            onClick={() => chatService.setCreatingConversation(false)}
            aria-label="Cancel"
          >
            <ArrowBackIosNew />
          </CloseConvCreationIcon>
          <CreateConversationAutocomplete
            sendDataToParent={handleAutocompleteChange}
          ></CreateConversationAutocomplete>
        </>
      )}
    </Container>
  );
};

export default PanelHeader;
