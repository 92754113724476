import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../../Loader";
import ColumnChart from "../../common/chart/ColumnChart";
import MultipleColumnChart from "../../common/chart/MultipleColumnChart";
import CollabDistribution from "./CollabDistribution";
import GraphFilterModal from "./GraphFilterModal";
import { useSelector } from "react-redux";

const CustomButton = styled(Button)({
  width: 200,
});

const StatsAdmin: React.FC<{
  data: any;
  loading: boolean;
  httpParams: any;
  setHttpParams: any;
}> = ({ data, loading, httpParams, setHttpParams }) => {
  const { t } = useTranslation("common");
  const [graph, setGraph] = useState<string>("connection");
  const [open, setOpen] = useState<boolean>(false);
  const context = useSelector((state: any) => state.auth.context);
  const advisorOrRecruiter = context === "advisor" || context === "recruiter";

  const advisorGraphs = [
    ...(advisorOrRecruiter ? [] : ["project"]),
    "connection",
    "questionnary",
    ...(advisorOrRecruiter ? [] : ["job", "work", "distribution_collab"]),
  ];

  const handleSelectChange = (event: any) => {
    setGraph(event.target.value);
  };

  const handleCleanFilters = () => {
    setHttpParams({});
  };

  return loading ? (
    <Loader />
  ) : data ? (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t("admin.graph.title")}
      </Typography>

      <Box display="flex" alignItems="center">
        <Box>
          <Box display="flex" gap={2} flexWrap="wrap">
            <FormControl>
              <Select value={graph} onChange={handleSelectChange} size="small">
                {advisorGraphs.map((label: string, key: number) => (
                  <MenuItem value={label} key={key}>
                    {t(`admin.graph.select.${label}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CustomButton variant="contained" onClick={() => setOpen(true)}>
              <Typography variant="body1">
                {t("admin.graph.filters")}
              </Typography>
            </CustomButton>
            {(typeof httpParams.start !== "undefined" ||
              typeof httpParams.end !== "undefined") && (
              <CustomButton variant="outlined" onClick={handleCleanFilters}>
                <Typography variant="body1">
                  {t("admin.graph.reset_filters")}
                </Typography>
              </CustomButton>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="h4" sx={{ mr: 1 }}></Typography>
          </Box>
        </Box>
      </Box>

      {data?.[graph] &&
        (graph === "distribution_collab" ? (
          <CollabDistribution data={data.distribution_collab} />
        ) : graph === "project" || graph === "questionnary" ? (
          <MultipleColumnChart type={graph} data={data[graph]} height={400} />
        ) : (
          <ColumnChart
            type={graph}
            data={data[graph].map((month: any) => month.series.value)}
            labels={data[graph].map((month: any) => month.name)}
            height={400}
          />
        ))}
      <GraphFilterModal
        open={open}
        close={() => setOpen(false)}
        width={500}
        httpParams={httpParams}
        setHttpParams={setHttpParams}
      />
    </>
  ) : (
    <></>
  );
};

export default StatsAdmin;
