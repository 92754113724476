import {
  DeleteForeverTwoTone,
  EditTwoTone,
  MoreVert,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-GB";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { createStyles, makeStyles } from "@mui/styles";
import { formatDistance } from "date-fns";
import {
  requestDeleteMessage,
  requestUpdateMessage,
} from "../../redux/actions/chatActions";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    textFieldFontSize: {
      color: "#fff",
      fontSize: 12,
    },
  })
);

const TextContainer = styled(Box, {
  shouldForwardProp: (props) => props !== "isRight",
})<{ isRight: boolean }>(({ isRight }) => ({
  display: "flex",
  flexDirection: isRight ? "row-reverse" : "column",
  alignItems: "flex-start",
  marginLeft: isRight ? "auto" : "",
}));

const FourthContainer = styled(Box, {
  shouldForwardProp: (props) => props !== "isLeft",
})<{ isLeft: boolean }>(({ isLeft }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: isLeft ? "flex-start" : "flex-end",
  alignItems: "center",
}));

const HiddenBox: any = styled(Box, {
  shouldForwardProp: (props) => props !== "isVisible" && props !== "isDisplay",
})(({ isVisible, isDisplay }: any) => ({
  visibility: isVisible ? "visible" : "hidden",
  display: !isDisplay ? "none" : "",
}));

const Text = styled(Box, {
  shouldForwardProp: (props) =>
    props !== "isRight" && props !== "flying" && props !== "sameAuthor",
})<{
  isRight: boolean;
  flying: boolean;
  sameAuthor: boolean;
}>(({ theme, isRight, flying, sameAuthor }: any) => ({
  backgroundColor: flying ? "#7890ef" : isRight ? "#4C6FFF" : "#d7d7eb",
  color: isRight || flying ? "#fff" : "default",
  maxWidth: "415px",
  padding: "12px",
  textAlign: "left",
  borderRadius: theme.shape.borderRadius8,
  fontSize: "12px",
  display: "flex",
  flexDirection: "column",
  ...(isRight
    ? sameAuthor
      ? { borderTopRightRadius: "0px" }
      : { borderBottomRightRadius: "0px" }
    : sameAuthor
    ? { borderTopLeftRadius: "0px" }
    : { borderBottomLeftRadius: "0px" }),
}));

const TimeBox = styled(Box, {
  shouldForwardProp: (props) => props !== "isRight",
})<{ isRight: boolean }>(({ isRight }) => ({
  fontSize: "10px",
  margin: "1px 5px",
  display: "inline-block",
  fontWeight: "bold",
  color: "#AFBBC6",
  flexDirection: isRight ? "row-reverse" : "row",
  marginLeft: isRight ? "auto" : "default",
}));

const MessageTextContainer: React.FC<{
  style: { own: boolean; flying: boolean; sameAuthorAsPrevious: boolean };
  mouseIsOver: boolean;
  onEdit: boolean;
  onDelete: boolean;
  setOnEdit: React.Dispatch<React.SetStateAction<boolean>>;
  textField: string;
  openDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDataToModal: React.Dispatch<React.SetStateAction<any>>;
  data: any;
  setTextField: React.Dispatch<React.SetStateAction<string>>;
  setOnDelete: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  style,
  mouseIsOver,
  onEdit,
  onDelete,
  setOnEdit,
  textField,
  openDeleteModal,
  setDataToModal,
  data,
  setTextField,
  setOnDelete,
}) => {
  const classes = useStyles();

  const [msgMenuAncor, setMsgMenuAncor] = useState<any>(null);
  const msgMenuDisplay = Boolean(msgMenuAncor);
  const id = msgMenuDisplay ? "message_menu" : undefined;
  const focusDiv = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const msgMenuClose = () => {
    setMsgMenuAncor(null);
  };

  const locale = localStorage.getItem("lang") || "fr";
  let fnsDate = formatDistance(
    new Date(data.updatedAt ?? data.createdAt ?? "now"),
    Date.now(),
    {
      includeSeconds: true,
      addSuffix: true,
      locale: locale === "fr" ? frLocale : enLocale,
    }
  );

  const setFnsDate = (tmpFnsDate: any) => {
    if (data?.createdAt === data?.updatedAt) return tmpFnsDate;
    return t("chat.updated") + " " + tmpFnsDate;
  };

  return (
    <TextContainer isRight={style.own}>
      <Box display="flex" flexDirection="column">
        <FourthContainer isLeft={!style.own}>
          <HiddenBox
            isVisible={style.own && mouseIsOver && !onEdit && !onDelete}
            isDisplay={style.own}
          >
            <Popover
              id={id}
              open={msgMenuDisplay}
              anchorEl={msgMenuAncor}
              onClose={msgMenuClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <Paper sx={{ width: 150, maxWidth: "100%" }}>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      msgMenuClose();
                      setOnEdit(true);

                      setTimeout(() => {
                        const input =
                          focusDiv.current?.querySelector("textarea");
                        input &&
                          input.setSelectionRange(
                            textField.length,
                            textField.length
                          );
                        input && input.focus();
                      }, 50);
                    }}
                  >
                    <ListItemIcon>
                      <EditTwoTone fontSize="small"></EditTwoTone>
                    </ListItemIcon>
                    <ListItemText>{t("chat.Edit")}</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      msgMenuClose();
                      openDeleteModal(true);
                      setDataToModal(data);
                    }}
                  >
                    <ListItemIcon>
                      <DeleteForeverTwoTone fontSize="small"></DeleteForeverTwoTone>
                    </ListItemIcon>
                    <ListItemText>{t("chat.Delete")}</ListItemText>
                  </MenuItem>
                </MenuList>
              </Paper>
            </Popover>

            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={(event: any) => {
                setMsgMenuAncor(event?.currentTarget);
              }}
            >
              <MoreVert />
            </IconButton>
          </HiddenBox>

          <Text
            isRight={style.own}
            flying={style.flying}
            sameAuthor={style.sameAuthorAsPrevious}
          >
            {onEdit ? (
              <>
                <TextField
                  id="outlined-multiline-flexible"
                  label=""
                  multiline
                  maxRows={10}
                  ref={focusDiv}
                  autoFocus
                  sx={{
                    backgroundColor: "#4c6fff",
                    color: "#fff",
                    borderRadius: "12px",
                    width: "390px",
                    fontSize: "10px",
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textFieldFontSize,
                    },
                    style: { padding: 8 },
                  }}
                  onChange={(e: any) => {
                    setTextField(e.target.value);
                  }}
                  value={textField}
                  fullWidth={true}
                />
              </>
            ) : (
              <>
                <Typography
                  component="p"
                  sx={{ fontSize: "12px", wordBreak: "break-word" }}
                >
                  {onDelete ? <del>{data.content}</del> : data.content}
                </Typography>
              </>
            )}
          </Text>
        </FourthContainer>

        <TimeBox isRight={style.own}>
          {onEdit || onDelete ? (
            <>
              <Typography>
                {"'Echap' pour "}
                <Link
                  href="#"
                  underline="none"
                  onClick={() => {
                    onEdit && setOnEdit(false);
                    onDelete && setOnDelete(false);
                  }}
                >
                  {"annuler"}
                </Link>
                {"- 'Enter' pour "}
                <Link
                  href="#"
                  underline="none"
                  onClick={() => {
                    data.content =
                      focusDiv.current?.querySelector("textarea")?.value;
                    style.flying = true;
                    onEdit &&
                      dispatch(requestUpdateMessage(data)) &&
                      setOnEdit(false);
                    onDelete &&
                      dispatch(requestDeleteMessage(data)) &&
                      setOnDelete(false);
                  }}
                >
                  {"valider"}
                </Link>
              </Typography>
            </>
          ) : (
            setFnsDate(fnsDate)
          )}
        </TimeBox>
      </Box>
    </TextContainer>
  );
};

export default MessageTextContainer;
