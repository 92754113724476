import axios from "axios";
import React, { createContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector, shallowEqual } from "react-redux";
import incResponses from "../../../_mocks/incResponses";

const IncTestContext = createContext<any>({});

export { IncTestContext };

const IncTestCtx = ({ children }: any) => {
  const [question, setQuestion] = useState<any>(null);
  const [testResponseBody, setTestResponseBody] = useState<any>({});
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const [responses, setResponses] = useState<any>([]);
  const responsesRef = useRef(responses);
  const [canNext, setCanNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [openSplashScreen, setOpenSplashScreen] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [step, setStep] = useState(0);
  const [lastAction, setLastAction] = useState<-1 | 0 | 1>(0);
  const navigate = useNavigate();

  const parameters = useSelector((state: any) => state.parameters);

  const canPass = parameters?.find((el: any) => el.type === "GENERAL_PARAMS")
    ?.parameters?.TESTS?.inc;

  const initQuestion = async () => {
    if (canPass) {
      setLoading(true);
      const res: any = (await axios.get(`tests/inc?question`)).data[
        "hydra:member"
      ][0];

      handleResponse(res);
      setLoading(false);
    }
  };

  useEffect(() => {
    initQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canPass]);

  useEffect(() => {
    setValidated(false);
    if (
      question?.questions?.length === 1 &&
      question?.questions[0].type === "9"
    ) {
      handleTestResponses([]);
      setCanNext(true);
    } else setCanNext(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  useEffect(() => {
    responsesRef.current = responses;
    const questionsAwaitingResponse = question?.questions?.filter(
      (el: any) => el.type !== "9"
    );
    let isOk = true;
    questionsAwaitingResponse?.forEach((el: any) => {
      const q = question?.questions?.find((e: any) => e.code === el.code);
      const answr = responses?.find((e: any) => e?.code === el?.code);
      if (answr?.answer?.length < q?.details.min_selection_choices || !answr) {
        isOk = false;
      }
    });
    setCanNext(isOk);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responses]);

  const handlePreviousTest = async () => {
    // handleNext();
    setLoading(true);
    const res: any = (
      await axios.get(
        `tests/inc?question=${question.questions[0].previousGroup}`
      )
    ).data["hydra:member"][0];

    if (isOnboarding) {
      setLastAction(-1);
    } else {
      setLastAction(0);
    }
    handleResponse(res);
    setLoading(false);
  };

  const handleSkip = async () => {
    if (
      process.env.REACT_APP_ENV !== "dev" &&
      process.env.REACT_APP_ENV !== "release"
    )
      return;
    const result = incResponses;
    try {
      await axios.post("partial_results", {
        result,
        test: "api/tests/inc",
        person: `api/people/${tokenData.person_id}`,
        nextGroup: "04_04",
      });
      const res: any = (
        await axios.post("partial_results/finish", {
          test: "api/tests/inc",
          person: `api/people/${tokenData.person_id}`,
        })
      ).data["hydra:member"][0];

      setOpenSplashScreen(false);

      setQuestion(res);
      handleResponse(res);
      setResponses([]);
      setLoading(false);

      if (isOnboarding) {
        setLastAction(1);
        setShowResults(true);
        setStep(2);
      } else {
        setLastAction(0);
        navigate("/app/inc");
      }
    } catch (err) {}
  };

  const handleNextTest = async () => {
    setValidated(true);
    if (!canNext) return;
    // handleNext();
    setLoading(true);
    let body = { ...testResponseBody };
    if (!testResponseBody.nextGroup) {
      body = { test: body.test, person: body.person };
      setOpenSplashScreen(true);
    }

    const res: any = (
      await axios.post(
        `partial_results${!body.nextGroup ? "/finish" : ""}`,
        body
      )
    ).data["hydra:member"][0];

    setOpenSplashScreen(false);

    setQuestion(res);
    handleResponse(res);
    setResponses([]);
    setLoading(false);

    if (!body.nextGroup) {
      if (isOnboarding) {
        setLastAction(1);
        setShowResults(true);
        setStep(2);
      } else {
        setLastAction(0);
        navigate("/app/inc");
      }
    }
  };

  useEffect(() => {
    handleTestResponses(responses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responses]);

  const handleTestResponses = (responses: any) => {
    if (
      question?.questions?.length === 1 &&
      question?.questions[0].type === "9"
    ) {
      responses = [
        {
          code: question?.questions?.[0].code,
          result: [],
        },
      ];
    }

    const formattedBody = {
      test: "api/tests/inc",
      person: `api/people/${tokenData.person_id}`,
      nextGroup: question?.questions?.[0]?.nextGroup,
      result: responses,
    };

    setTestResponseBody(formattedBody);
    if (
      question?.questions?.[0]?.groupKey?.split("_")[1] === "01" &&
      question?.questions?.[0]?.groupKey?.split("_")[0] !== "01"
    ) {
      switch (lastAction) {
        case 1:
          handleNextTest();
          break;
        case -1:
          // handlePreviousTest();
          break;
      }
    }
  };

  const handleUserResponse = (index: number, response: any) => {
    const resp: any = [...responsesRef.current];
    resp[index] = response;
    setResponses(resp);
  };

  const handleResponse = (res: any) => {
    if (res.result) {
      const keys = Object.keys(res.result);
      if (res.questions.find((el: any) => el.type === "7")) {
        keys.forEach((key: string) => {
          const index = res.questions.findIndex((el: any) =>
            key.includes(el.code)
          );

          res.questions[index].details.cursor_start_value =
            res.result[key].result[0].value;
        });
      }

      keys.forEach((key: string) => {
        const index = res.questions.findIndex((el: any) =>
          key.includes(el.code)
        );
        if (index >= 0) {
          if (res.result[key].answer?.length > 0) {
            const answers = res.result[key].answer;
            answers.forEach((answer: any, i: number) => {
              const questionIndex = res.questions[index].answers.findIndex(
                (el: any) => String(el.code) === String(answer.code)
              );

              res.questions[index].answers[questionIndex].previousValue =
                answer.value;
              res.questions[index].answers[questionIndex].listIndex = i;
            });
          }
        }
      });
    }
    setQuestion(res);
  };

  const value = {
    handleNextTest,
    handlePreviousTest,
    handleUserResponse,
    responses,
    setResponses,
    responsesRef,
    validated,
    openSplashScreen,
    question,
    setIsOnboarding,
    loading,
    showResults,
    step,
    setStep,
    handleSkip,
  };
  return (
    <IncTestContext.Provider value={value}>{children}</IncTestContext.Provider>
  );
};

export default IncTestCtx;
