import React, { useState } from "react";
import { styled, Typography, Box, ButtonBase } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import DemoModal from "./DemoModal";

const Header = styled("div")`
  background-color: #fbf7e9;
  padding: 60px 30px 95px 30px;
  height: 100%;
  min-height: 100vh;
  color: #303030;
  /* @media screen and (min-width: 1337px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55vh;
  } */
`;

const InfosCtn = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  @media screen and (max-width: 1337px) {
    flex-direction: column-reverse;
  }
`;

const PtwLogo = styled((props) => (
  <img {...props} alt="ptw logo" src="/static/vivatech/ricane.png" />
))(() => ({
  // height: "150px",
  height: "500px",
  marginTop: "-150px",
  "@media screen and (max-width: 1337px)": {
    display: "none",
  },
}));

const TextsContainer = styled("div")`
  @media screen and (max-width: 1337px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled(Typography)`
  font-family: "Product Sans";
  font-weight: bold;
  font-size: 48px;
  line-height: 53px;
  max-width: 25ch;
  @media screen and (max-width: 1337px) {
    text-align: center;
  }
`;

const Subtitle = styled((props: any) => (
  <Typography {...props} variant="h4">
    {props.children}
  </Typography>
))(
  () => `
  font-weight: normal;
  max-width: 65ch;
  margin-top: 14px;
`
);

const MTLogo = styled((props) => (
  <div {...props}>
    <img src="static/logo/MT-Logo-Colors.svg" alt="monkey tie logo" />
  </div>
))(
  () => `
  display: flex;
  justify-content: center;
  img {
    height: 230px;
  }
`
);

const VivatechHome = () => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState<string | null>(null);

  const redirectPTW = () => {
    window.location.href = "https://pole-emploi.passtowork.fr";
  };

  return (
    <Page title={t("onboarding.label")}>
      <Header>
        <MTLogo />
        <InfosCtn>
          <TextsContainer>
            {/* <Box display="flex" justifyContent="center" marginBottom={2}>
              <PtwLogo />
            </Box> */}
            <Title>
              Imaginez un monde dans lequel chacun puisse découvrir son
              potentiel
            </Title>
            <Subtitle>
              et se révéler dans l’activité qui lui correspond le mieux
            </Subtitle>

            <hr
              style={{ marginTop: "45px", marginBottom: "45px", width: "100%" }}
            />

            <Typography
              sx={{ display: "flex", alignItems: "center", mb: 2 }}
              variant="h4"
              fontWeight="bold"
            >
              <VerifiedOutlinedIcon sx={{ marginRight: 1 }} /> Créez votre
              compte dès maintenant !
            </Typography>

            <Box display="flex" width="100%" flexWrap="wrap">
              <AdvisorButton onClick={setOpen.bind(this, "advisor")}>
                Je suis un conseiller
              </AdvisorButton>
              <RecruiterButton onClick={setOpen.bind(this, "recruiter")}>
                Je suis un recruteur
              </RecruiterButton>
              <OtherButton onClick={redirectPTW}>
                Je suis un particulier <OpenInNewOutlinedIcon />
              </OtherButton>
            </Box>
          </TextsContainer>
          {/* <Box>
            <Placeholder />
          </Box> */}
          <Box display="flex" justifyContent="center" marginBottom={2}>
            <PtwLogo />
          </Box>
        </InfosCtn>
      </Header>

      <DemoModal open={open} handleClose={setOpen.bind(this, null)} />
      {/* <VivatechExperience /> */}
    </Page>
  );
};

export default VivatechHome;

const AdvisorButton = styled(ButtonBase)`
  border-radius: 30px;
  padding: 15px 25px;
  background-color: #1a52c1;
  color: white;
  font-size: 14px;
  margin-right: 30px;
  margin-bottom: 20px;
`;

const RecruiterButton = styled(ButtonBase)`
  border-radius: 30px;
  padding: 15px 25px;
  background-color: #e62964;
  color: white;
  font-size: 14px;
  margin-right: 30px;
  margin-bottom: 20px;
`;

const OtherButton = styled(ButtonBase)`
  border-radius: 30px;
  padding: 15px 25px;
  background-color: #303030;
  color: white;
  font-size: 14px;
  margin-bottom: 20px;
`;
