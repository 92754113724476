import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../../../_utils/modalStyle";

const GraphFilterModal: React.FC<{
  open: boolean;
  close: () => void;
  width?: number;
  httpParams: any;
  setHttpParams: any;
}> = ({ open, close, width, httpParams, setHttpParams }) => {
  const [startMonth, setStartMonth] = useState<number>();
  const [startYear, setStartYear] = useState<number>();
  const [endMonth, setEndMonth] = useState<number>();
  const [endYear, setEndYear] = useState<number>();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (typeof httpParams.start !== "undefined") {
      const date = httpParams.start.split("-");
      setStartMonth(date[1]);
      setStartYear(date[0]);
    }

    if (typeof httpParams.end !== "undefined") {
      const date = httpParams.end.split("-");
      setEndMonth(date[1]);
      setEndYear(date[0]);
    }
  }, [httpParams]);

  const handleMonthChange = (type: "start" | "end") => {
    return (event: any) => {
      type === "start"
        ? setStartMonth(event.target.value)
        : setEndMonth(event.target.value);
    };
  };

  const handleYearChange = (type: "start" | "end") => {
    return (event: any) => {
      type === "start"
        ? setStartYear(event.target.value)
        : setEndYear(event.target.value);
    };
  };

  const handleConfirm = () => {
    const newParams = { ...httpParams };
    if (startMonth && startYear) {
      newParams.start = `${startYear}-${startMonth}-1`;
    }
    if (endMonth && endYear) {
      newParams.end = `${endYear}-${endMonth}-1`;
    }

    setHttpParams(newParams);
    close();
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: {
            xs: "95%",
            sm: !width ? "auto" : width,
            md: !width ? "auto" : width,
          },
        }}
      >
        <CardContent>
          <Box display="flex" sx={{ mb: 3 }}>
            <Typography
              variant="h5"
              sx={{ ml: "18px", flexGrow: 1, textAlign: "center" }}
            >
              {t("admin.graph.filters_title")}
            </Typography>
            <IconButton onClick={close} sx={{ mt: -2, mr: -2, height: "40px" }}>
              <HighlightOff />
            </IconButton>
          </Box>
          <Typography variant="subtitle2">
            {t("admin.graph.start_date")}
          </Typography>
          <Box display="flex">
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel>{t("admin.graph.month_label")}</InputLabel>
              <Select
                value={startMonth ?? ""}
                label="Age"
                onChange={handleMonthChange("start")}
              >
                <MenuItem value={1}> {t("admin.graph.month.january")}</MenuItem>
                <MenuItem value={2}>{t("admin.graph.month.february")}</MenuItem>
                <MenuItem value={3}>{t("admin.graph.month.march")}</MenuItem>
                <MenuItem value={4}>{t("admin.graph.month.april")}</MenuItem>
                <MenuItem value={5}>{t("admin.graph.month.may")}</MenuItem>
                <MenuItem value={6}>{t("admin.graph.month.june")}</MenuItem>
                <MenuItem value={7}>{t("admin.graph.month.july")}</MenuItem>
                <MenuItem value={8}>{t("admin.graph.month.august")}</MenuItem>
                <MenuItem value={9}>
                  {t("admin.graph.month.september")}
                </MenuItem>
                <MenuItem value={10}>{t("admin.graph.month.october")}</MenuItem>
                <MenuItem value={11}>
                  {t("admin.graph.month.november")}
                </MenuItem>
                <MenuItem value={12}>
                  {t("admin.graph.month.december")}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel>{t("admin.graph.year_label")}</InputLabel>
              <Select
                value={startYear ?? ""}
                label="Age"
                onChange={handleYearChange("start")}
              >
                <MenuItem value={2019}>2019</MenuItem>
                <MenuItem value={2020}>2020</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Typography variant="subtitle2">
            {t("admin.graph.end_date")}
          </Typography>
          <Box display="flex">
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel>{t("admin.graph.month_label")}</InputLabel>
              <Select
                value={endMonth ?? ""}
                label="Age"
                onChange={handleMonthChange("end")}
              >
                <MenuItem value={1}> {t("admin.graph.month.january")}</MenuItem>
                <MenuItem value={2}>{t("admin.graph.month.february")}</MenuItem>
                <MenuItem value={3}>{t("admin.graph.month.march")}</MenuItem>
                <MenuItem value={4}>{t("admin.graph.month.april")}</MenuItem>
                <MenuItem value={5}>{t("admin.graph.month.may")}</MenuItem>
                <MenuItem value={6}>{t("admin.graph.month.june")}</MenuItem>
                <MenuItem value={7}>{t("admin.graph.month.july")}</MenuItem>
                <MenuItem value={8}>{t("admin.graph.month.august")}</MenuItem>
                <MenuItem value={9}>
                  {t("admin.graph.month.september")}
                </MenuItem>
                <MenuItem value={10}>{t("admin.graph.month.october")}</MenuItem>
                <MenuItem value={11}>
                  {t("admin.graph.month.november")}
                </MenuItem>
                <MenuItem value={12}>
                  {t("admin.graph.month.december")}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel>{t("admin.graph.year_label")}</InputLabel>
              <Select
                value={endYear ?? ""}
                label="Age"
                onChange={handleYearChange("end")}
              >
                <MenuItem value={2019}>2019</MenuItem>
                <MenuItem value={2020}>2020</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mt: -1,
            mb: 1,
          }}
        >
          <Button
            variant="contained"
            onClick={handleConfirm}
            sx={{ m: 1, fontSize: "14px" }}
            size="large"
          >
            {t("btn.valid")}
          </Button>
          <Button
            variant="outlined"
            onClick={close}
            sx={{ m: 1, fontSize: "14px" }}
            size="large"
          >
            {t("btn.cancel")}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default GraphFilterModal;
