import {
  Card,
  Modal,
  CardContent,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "../../contexts/ProfileContext";
import useFetch from "../../hooks/useFetch";
import peopleAPI from "../../_api/_people";
import UsersAPI from "../../_api/_usersAPI";
import { modalStyle } from "../../_utils/modalStyle";
import ProfileEditFormBody from "./ProfileEditFormBody";
import { useSelector, shallowEqual } from "react-redux";
import countriesAPI from "../../_api/_countriesAPI";
import { toast } from "react-toastify";

const ProfileEditModal: React.FC<{ open: boolean; close: any }> = ({
  close,
  open,
}) => {
  const { t } = useTranslation("common");
  const { peopleData, setPeopleData } = useContext(ProfileContext);
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const [loading, setLoading] = useState(false);
  const [countriesList, setCountriesList] = useState<any>([]);
  const { data } = useFetch(countriesAPI.get({ pagination: false }), !open);

  useEffect(() => {
    if (data?.["hydra:member"]) {
      setCountriesList(
        data?.["hydra:member"].sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        )
      );
    }
  }, [data]);

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    gender: "",

    addressCountry: "",
    addressLocality: "",
    mainAddress: true,
    postalCode: "",
    streetAddress: "",
    zoom: "",
    teams: "",
    linkedin: "",
    image: "",
  });

  React.useEffect(() => {
    if (peopleData)
      setForm({
        firstName: peopleData.firstName,
        lastName: peopleData.lastName,
        gender: peopleData.gender,
        addressCountry: peopleData.postalAddress?.addressCountry?.id,
        addressLocality: peopleData.postalAddress?.addressLocality,
        mainAddress: peopleData.postalAddress?.mainAddress || true,
        postalCode: peopleData.postalAddress?.postalCode,
        streetAddress: peopleData.postalAddress?.streetAddress,
        zoom: peopleData.otherData?.visio?.zoom,
        teams: peopleData.otherData?.visio?.teams,
        linkedin: peopleData.otherData?.visio?.linkedin,
        image: peopleData?.image?.contentUrl,
      });
  }, [peopleData]);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        postalAddress:
          values.addressCountry ||
          values.addressLocality ||
          values.postalCode ||
          values.streetAddress
            ? {
                ...(values.addressCountry && {
                  addressCountry: `/api/countries/${values.addressCountry}`,
                }),
                addressLocality: values.addressLocality,
                mainAddress: values.mainAddress,
                postalCode: values.postalCode,
                streetAddress: values.streetAddress,
              }
            : null,
        otherData: {
          visio: {
            zoom: values.zoom,
            teams: values.teams,
            linkedin: values.linkedin,
          },
          hobbies: peopleData.otherData?.hobbies || [],
        },
      };
      const res = await UsersAPI.put(tokenData?.user_id, body);
      await peopleAPI.put(tokenData?.person_id, body);

      if (values.addressCountry && body?.postalAddress) {
        body.postalAddress.addressCountry = countriesList.find(
          (el: any) => el.id === values.addressCountry
        );
      }
      if (res) setPeopleData({ ...peopleData, ...body });
      setLoading(false);
      toast.success(t("success.profile_modified"));
      close();
    } catch (err) {
      console.log("ERR", err);
      setLoading(false);
      toast.error(t("errors.error_append"));
    }
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
        }}
      >
        <CardContent sx={{ pt: 0 }}>
          <Box
            sx={{ width: "100%", display: "flex", alignItems: "start", mt: 3 }}
          >
            <Typography
              variant="h5"
              sx={{ ml: "24px", textAlign: "center", flexGrow: 1 }}
            >
              {t("profilepage.edit")}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{ ml: "auto", mt: -2, mr: -2 }}
            >
              <HighlightOff />
            </IconButton>
          </Box>

          {open && (
            <ProfileEditFormBody
              countriesList={countriesList}
              form={form}
              close={close}
              submit={handleSubmit}
              loading={loading}
            />
          )}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ProfileEditModal;
