import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import axios from "axios";
import { useTranslation } from "react-i18next";

const BlackListComponent: React.FC<{
  id: string;
  isBlackListed: boolean;
  reload?: any;
}> = ({ id, isBlackListed, reload }) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState<boolean>(false);
  const handleBlackList = async () => {
    setLoading(true);
    await axios
      .post(`blacklist/${isBlackListed ? "delete" : "add"}-item`, {
        work: id,
      })
      .then((res) => {
        reload(!isBlackListed);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Tooltip
      title={`${t(
        `work_suggest.${isBlackListed ? "remove" : "add"}_blacklist`
      )}`}
    >
      {loading ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={20} />
        </Box>
      ) : (
        <IconButton
          sx={{ opacity: 0.7, width: 20, height: 20 }}
          onClick={handleBlackList}
        >
          {isBlackListed ? (
            <VisibilityOutlinedIcon />
          ) : (
            <VisibilityOffOutlinedIcon />
          )}
        </IconButton>
      )}
    </Tooltip>
  );
};

export default BlackListComponent;
